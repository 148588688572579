@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
*{
    font-family: 'Poppins', sans-serif;
}
html{
    font-size: 16px;
}
#root{
    overflow: hidden;
    height: 100%;
}
.App{
    height: 100%;
}
.parentContainer{
    overflow: hidden;
    width: 100%;
    height: 100vh;
    /* border: solid 2px red; */
    display: flex;
    flex-direction: row;
    outline: none;
}
.formContainer{
    background-color: var(--default-bg);
    height: 100%;
    /* border: solid 3px purple; */
    justify-self: flex-end;
    width: 36%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
.bgimgContainer{
    width: 64%;
    background-image: url('../images/bg-login.jpg');
    background-size: cover;
    background-position: -5rem;
}
.innerContainer2{
    /* background-color: antiquewhite; */
    /* border: 2px solid royalblue; */
    padding: 1rem;
    /* font-weight: 700; */
    color: var(--normalfont-color);
    height: 80vh;
}
input[type='radio']{
    /* opacity: 0; */
    display: none;
}
.loginLabel{
    font-size: 2rem;
    padding: 0 0.5rem 0 0.5rem;
    color: #a3a3a3;
    border-radius: 0.2rem;
    cursor: default;
}
.loginLabel:hover{
    /* background-color: #ffe6efbd; */
    color: #5c5c5c;
}
.innerContainer{
    margin-top: 10vh;
    /* border: 1px solid black; */
    height: 100%;
    width: 80%;
}
input[type='radio']:checked + label{
    color:var(--normalfont-color);
    font-weight: 600;
    border-radius: 0;
    border-bottom: 3px solid #ff1e70;
}
input[type='radio']:not(:checked) + label{
    cursor: pointer;
}
.switch{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.loginInputLabel{
    margin-top: 5vh;
    font-size: 1rem;
    color: #a3a3a3;
    font-weight: 600;
}
.innerContainer2 input{
    background: transparent;
    border-bottom: solid 2px #a3a3a3;
    width: 100%;
    padding: 0.6rem;
    padding-left: 0;
    font-size: 1.2rem;
    color: var(--normalfont-color);
}
.innerContainer2 input:focus{
    border-color: #ff1e70!important;
}
.innerContainer2 input::placeholder{
    color: #a3a3a3;
    font-size: 1.2rem;
}
.primarybtn{
    color: white;
    border-radius: 0.2rem;
    padding: 0.4rem 1rem 0.4rem 1rem;
    font-weight: 600;
    font-size: 1.2rem;
    margin-top: 4rem;
    border: none!important;
    background-color: #ff1e70;
  }
  .primarybtn:hover{
    background-color: #ff3d84;
    border: none;
  }
  .primarybtn:active, .primarybtn:focus{
    background-color: #e90055;
  }
  .normalbtn{
    color: var(--normalfont-color);
    border-radius: 0.2rem;
    padding: 0.4rem 1rem 0.4rem 1rem;
    font-size: 1.2rem;
    border: none!important;
    margin-top: 4.2rem;
    background-color: transparent;
  }
  .normalbtn:hover{
    /* border: 2.5px solid #ff1e70 ; */
    color: #ff1e70;
    border: none;
  }
  .normalbtn:active, .normalbtn:focus{
    color: #e90055;
  }
.buttoncont{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
  /* input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  } */

  /* Firefox */
  /* input[type=number] {
    -moz-appearance: textfield;
  } */
.innerContainer3{
    width: 100%;
    height: 100%;
}