@import "./allColors.css";

.wrapper{
    width: 100%;
    height: 100%;
    background-color: var(--default-bg);
    border-radius: 0.3rem;
    padding: 1rem;
    overflow-y: auto;
}
.header{
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--normalfont-color);
    text-align: left;
}
.formBody{
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 90%;
}
.formGroup{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 1rem;
    width: 100%;
}
.formGroup .input{
    border-radius: 0.3rem;
    border: solid 2px #575757!important;
    padding: 0.3rem;
    background-color: var(--default-bg);
    color: var(--normalfont-color);
}
.formGroup .input:focus, .formGroup .input:active{
    border: solid 2px var(--primary-accent)!important;
}
.innerCont{
    width: 100%;
    display: flex;
    flex-direction: row;
}
.innerCont span{
    padding: 0.3rem;
    color: red;
    font-size: 0.9rem;
}
label{
    color: var(--normalfont-color);
}