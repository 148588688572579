@import './allColors.css';
.wrapper{
    width: 100%;
    height: 100%;
    background-color: var(--default-bg);
    border-radius: 0.3rem;
    padding: 1rem;
    overflow-y: auto;
}
.header{
    font-size: 1.5rem;
    font-weight: bold;
    text-align: left;
    color: var(--normalfont-color);
}
.colorArea{
    width: 95%;
    background-color: var(--settings-bg);
    margin: 1rem;
    border-radius: 0.3rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 1rem;
}
.subHeader{
    font-size: 1rem;
    font-weight: 600;
    color: var(--normalfont-color);
    text-align: left;
}
.colorButton{
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 0;
    cursor: pointer;
    margin-right: 1rem;
}
.colorButton_selected{
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;
    cursor: pointer;
    /* border: white solid 4px; */
}
.lightCheck{
    color: white;
    font-size: 1.5rem;
}
.darkCheck{
    color: #3a3a3a;
    font-size: 1.5rem;
}
.redTheme{
    background-color: #ff0000;
}
.defaultTheme{
    background-color: #9500ff;
}
.yellowTheme{
    background-color: #ffd600;
}
.blueTheme{
    background-color: #0047ff;
}
.orangeTheme{
    background-color: #ff7a00;
}
.greenTheme{
    background-color: #20E700;
}
.defaultBg{
    background-color: white;
}
.darkBg{
    background-color: #3a3a3a;
}