@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
@import "allColors.css";

* {
	margin: 0px;
	padding: 0px;
	box-sizing: border-box;
}
input,
select {
	outline: none;
	border: none;
}
a {
	outline: none;
}
/* input[type="number"] {
	-moz-appearance: textfield;
	appearance: none;
	-webkit-appearance: none;
} */
h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0px;
}

p {
	font-family: Poppins-Regular;
	font-size: 14px;
	line-height: 1.7;
	color: #666666;
	margin: 0px;
}

ul,
li {
	margin: 0px;
	list-style-type: none;
}
/* input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
	-webkit-appearance: none;
} */

button {
	outline: none !important;
	border: none;
	background: transparent;
}

button:hover {
	cursor: pointer;
}
body,
html {
	height: 100%;
	font-family: Poppins-Regular, sans-serif;
}
html {
	box-sizing: border-box;
	font-size: 16px;
	font-family: "Poppins", sans-serif;
	background-color: var(--body-bg);
}
body {
	background: var(--body-bg) !important;
	color: var(--normalfont-color);
	overflow-x: hidden;
}
* {
	font-family: "Poppins", sans-serif;
}

/* Main Content */

.mapContainer {
	padding: 1rem;
}
.pad {
	margin: 1rem !important;
	background-color: var(--default-bg);
	padding-bottom: 2rem;
}
.wrapper .main_content {
	width: auto;
	margin-left: 4rem;
	padding-top: 4.5rem;
}

.wrapper .main_content .header {
	padding: 20px;
	background: var(--default-bg);
	color: #717171;
	border-bottom: 1px solid #e0e4e8;
}

.wrapper .main_content .info {
	margin: 20px;
	color: #717171;
	line-height: 25px;
}
/* .wrapper.text-center{
  background-color: #f6f6f6;
} */
.wrapper .main_content .info div {
	margin-bottom: 20px;
}
.downloadCsvButton {
	margin-right: 1rem;
	border-radius: 0.3rem;
	padding: 0.6rem 1rem 0.6rem 1rem;
	border: solid 3px var(--normalfont-color);
	font-weight: bold;
	color: var(--normalfont-color);
}
.downloadCsvButton:hover {
	border-color: var(--primary-hover);
	color: var(--primary-hover);
}
.buttonContainer {
	border-radius: 0.3rem;
	padding: 0.6rem 1rem 0.6rem 1rem;
	background-color: var(--primary-accent) !important;
	display: flex;
	flex-direction: row;
	align-items: center;
	border: solid 3px var(--primary-accent);
	cursor: pointer;
}
.buttonContainer:hover {
	background-color: var(--primary-hover) !important;
	border-color: var(--primary-hover);
}
.buttonContainer:active {
	background-color: var(--primary-active) !important;
	border-color: var(--primary-active);
}
.buttonText {
	text-decoration: none;
	color: var(--mainfont-color);
	font-weight: bold;
	justify-self: flex-end;
	font-family: "Poppins", sans-serif;
}
.buttonText:hover {
	text-decoration: none;
	color: var(--mainfont-color);
	font-weight: bold;
	justify-self: flex-end;
}
.header {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}
.welcomeContainer {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	font-family: var(--font-family);
	justify-content: flex-start;
}
.mainLine {
	color: var(--normalfont-color);
	font-family: var(--font-family);
	font-size: 1.2rem;
	font-weight: 600;
}
.subLine {
	color: gray;
	font-family: var(--font-family);
	font-size: 0.8rem;
}
.modal-content {
	background-color: var(--default-bg) !important;
	font-family: var(--font-family) !important;
}
.modal-title {
	color: var(--normalfont-color) !important;
	font-weight: 700;
}
.modal input {
	border: 1.2px solid black;
	border-radius: 0.3rem;
	background-color: var(--default-bg) !important;
	color: var(--normalfont-color) !important;
}
.modal input:focus {
	-webkit-box-shadow: 0px 0px 2px 1px var(--primary-accent) !important;
	-moz-box-shadow: 0px 0px 2px 1px var(--primary-accent) !important;
	box-shadow: 0px 0px 2px 1px var(--primary-accent) !important;
	background-color: var(--default-bg) !important;
	color: var(--normalfont-color) !important;
}
.modal select {
	outline: none;
	border: 1.2px solid black;
	border-radius: 0.3rem;
	background-color: var(--default-bg) !important;
	color: var(--normalfont-color) !important;
}
.modal select:focus {
	-webkit-box-shadow: 0px 0px 2px 1px var(--primary-accent) !important;
	-moz-box-shadow: 0px 0px 2px 1px var(--primary-accent) !important;
	box-shadow: 0px 0px 2px 1px var(--primary-accent) !important;
	background-color: var(--default-bg) !important;
	color: var(--normalfont-color) !important;
}
.main_content select {
	outline: none;
	border: 1.2px solid transparent;
	border-radius: 0.3rem;
	background-color: var(--default-bg) !important;
	color: var(--normalfont-color) !important;
	width: 8rem;
}
.main_content select:focus {
	-webkit-box-shadow: 0px 0px 2px 1px var(--primary-accent) !important;
	-moz-box-shadow: 0px 0px 2px 1px var(--primary-accent) !important;
	box-shadow: 0px 0px 2px 1px var(--primary-accent) !important;
	background-color: var(--default-bg) !important;
	color: var(--normalfont-color) !important;
}
.modal-body {
	color: var(--normalfont-color) !important;
}
.close span {
	color: var(--normalfont-color) !important;
}
input[type="date"] {
	background-color: var(--default-bg);
	color: var(--normalfont-color);
}
input[type="date"]:focus,
input[type="date"]:active {
	background-color: var(--default-bg);
	color: var(--normalfont-color);
	-webkit-box-shadow: 0px 0px 2px 1px var(--primary-accent);
	-moz-box-shadow: 0px 0px 2px 1px var(--primary-accent);
	box-shadow: 0px 0px 2px 1px var(--primary-accent);
}
.custom-file-label {
	color: var(--normalfont-color) !important;
	background-color: var(--default-bg) !important;
}
.custom-footer {
	justify-content: space-between !important;
}
.primary-btn {
	border: none !important;
	background-color: var(--primary-accent) !important;
	font-weight: bold !important;
	color: var(--mainfont-color) !important;
}
.primary-btn:hover {
	background-color: var(--primary-hover) !important;
	border: none !important;
}
.primary-btn:active,
.primary-btn:focus {
	border: none !important;
	background-color: var(--primary-active) !important;
}
.edit-btn {
	border: none !important;
	background-color: var(--edit-normal) !important;
	font-weight: bold !important;
}
.edit-btn:hover {
	background-color: var(--edit-hover) !important;
	border: none !important;
}
.edit-btn:active {
	border: none !important;
	background-color: var(--edit-active) !important;
}
.delete-btn {
	border: none !important;
	background-color: var(--delete-normal) !important;
	font-weight: bold !important;
}
.delete-btn:hover {
	background-color: var(--delete-hover) !important;
	border: none !important;
}
.delete-btn:active {
	border: none !important;
	background-color: var(--delete-active) !important;
}
.normal-btn {
	color: var(--normalfont-color) !important;
}
.normal-btn:hover {
	background-color: var(--reacttable-hover) !important;
	/* font-weight: bold!important; */
}
.normal-btn:active {
	background-color: var(--reacttable-hover) !important;
	/* font-weight: bold!important; */
}
.calendarContainer {
	margin-top: 1rem;
	padding: 1rem;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}
.dateLabel {
	margin-top: 1.5rem;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}
.table-btn {
	background-color: #c692e9;
	padding: 0.1rem 0.5rem 0.1rem 0.5rem;
	border-radius: 0.3rem;
	color: var(--mainfont-color);
}
.table-btn:hover {
	background-color: #ab5fdd;
}
.table-btn:active {
	background-color: #7b2bb1;
}
.font-weight-600 {
	font-weight: 600 !important;
	color: var(--normalfont-color);
}

hr {
	background-color: var(--normalfont-color) !important;
}
.extraClass {
	font-size: 1rem !important;
	pointer-events: auto !important;
	font-weight: bold;
}
.extraClass:hover {
	visibility: visible !important;
	opacity: 1 !important;
}
.driverDetailsButtons {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}
.timeLineContainer {
	width: 100%;
	margin-top: 3vh;
	margin-bottom: 5vh;
	background-color: var(--default-bg);
	height: auto;
	padding: 1rem;
}
.analyticsMapContainer {
	width: auto;
	margin: 3vh;
	background-color: transparent;
	height: auto;
	padding: 1rem;
}
.timeLineContainerHeader {
	background-color: var(--default-bg);
	text-align: left;
	margin-bottom: 1rem;
}
.loadingContainer {
	width: "100%";
	height: "100%";
	background: var(--default-bg);
	display: "flex";
	flex-direction: "row";
	justify-content: "center";
	align-items: "center";
	color: var(--normalfont-color);
}
.text-dark2 {
	color: var(--normalfont-color) !important;
}
/* .mapboxgl-map{
	position: absolute;
	left: 4rem!important;
	top: 4.5rem!important;
	border: solid 1rem transparent!important;
	width: 100%!important;
	height: 100%!important;
}
#deckgl-overlay{
	position: absolute;
	left: 4rem!important;
	top: 4.5rem!important;
	border: solid 1rem transparent!important;
	width: 80%!important;
	height: 90%!important;
} */
#deckgl-wrapper {
	position: absolute;
	left: 5rem !important;
	top: 7.5rem !important;
	border: solid 1rem var(--default-bg) !important;
	width: calc(100vw - 6rem) !important;
	padding-right: 2rem !important;
	/* margin-right: 1rem!important; */
	height: calc(100vh - 8.5rem) !important;
}
#control-panel {
	position: absolute;
	top: 8rem;
	right: 1.5rem;
	margin: 12px;
	padding: 20px;
	font-size: 12px;
	line-height: 1.5;
	z-index: 1;
	background: #fff;
	font-family: Helvetica, Arial, sans-serif;
	box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
	color: black;
}
#control-panel label {
	color: black;
}
#control-panel div {
	display: flex;
	flex-direction: column;
	width: 100%;
	justify-content: space-between;
	margin-bottom: 1.5rem;
}
.PhoneInputInput {
	padding: 0.4rem;
}
.dropzone {
	text-align: center;
	padding: 20px;
	border: 2px dashed var(--normalfont-color);
	background-color: transparent;
	color: var(--normalfont-color) !important;
}
.analyticsInputPanel {
	margin-left: 1rem !important;
	width: calc(100vw - 6rem) !important;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding-top: 0.5rem;
}
.analyticsInputPanelInner {
	width: 30%;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	padding-top: 2px;
}
.checkBoxGroup {
	display: flex;
	flex-direction: row;
	align-items: center;
}
.checkBoxGroup label {
	margin-bottom: 0rem;
}
.slider {
	-webkit-appearance: none;
	width: 100%;
	height: 5px;
	border-radius: 5px;
	background: #d3d3d3;
	outline: none;
	opacity: 0.7;
	-webkit-transition: 0.2s;
	transition: opacity 0.2s;
	border-color: transparent;
}

.slider::-webkit-slider-thumb {
	-webkit-appearance: none;
	appearance: none;
	width: 15px;
	height: 15px;
	border-radius: 50%;
	background: var(--primary-accent);
	border-color: transparent;
	cursor: pointer;
}

.slider::-moz-range-thumb {
	width: 15px;
	height: 15px;
	border-radius: 50%;
	background: var(--primary-accent);
	border-color: transparent;
	cursor: pointer;
}
.containerCheckbox {
	display: block;
	position: relative;
	padding-left: 35px;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

/* Hide the browser's default checkbox */
.containerCheckbox input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

/* Create a custom checkbox */
.checkmark {
	position: absolute;
	top: -12.5px;
	left: 0;
	height: 25px;
	width: 25px;
	background-color: #eee;
	border-radius: 0.3rem;
}

/* On mouse-over, add a grey background color */
.containerCheckbox:hover input ~ .checkmark {
	background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.containerCheckbox input:checked ~ .checkmark {
	background-color: var(--primary-accent);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

/* Show the checkmark when checked */
.containerCheckbox input:checked ~ .checkmark:after {
	display: block;
	color: black;
}

/* Style the checkmark/indicator */
.containerCheckbox .checkmark:after {
	left: 10px;
	top: 6px;
	width: 5px;
	height: 10px;
	border: solid var(--mainfont-color);
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}
.fileNameContainer{
	display: flex;
	flex-direction: row;
	width: 12rem;
	justify-content: space-between;
	align-items: center;
}
.fileNameContainer svg:hover .path1{
	fill: var(--primary-accent);
	cursor: pointer;
}
.fileNameContainer svg .path2{
	fill: var(--default-bg);
}
.__react_component_tooltip{
	margin-top: 0.7rem!important;
}
.error{
	color: red;
	font-size: 0.9rem;
}
/* .error404Container{
	background-color: var(--default-bg);
	width: calc(100vw - 2rem);
	height: calc(100vh - 2rem);
	position: absolute;
	top: 1rem;
	left: 1rem;
	background-image: url('../images/g10404.svg');
	background-repeat: no-repeat;
	background-position: 103%;
	background-size: contain;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
} */
.error404Container2{
	width: 45%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	padding-left: 2rem;
	padding-top: 10vh;
}
.error404Container .error404Container2 a{
	text-decoration: none;
}
.error404Container .error404Container2 a:hover{
	text-decoration: none;
}
#logo1{
	position: relative;
	left: 0.5rem;
	bottom: 0rem;
}
#logo2{
	position: relative;
	bottom: 1.7rem;
	left: 3.5rem;
}