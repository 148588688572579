@import './allColors.css';
.wrapperSettingMenu{
    position: relative;
    background-color: var(--default-bg);
    top: 2rem;
    width: 16rem;
    margin-left: 2rem;
    border-radius: 0.3rem;
    height: calc(100vh - 8.5rem);
}
.SettingMenu{
    height: 100%;
    padding: 1rem 0 1rem 0;
}
.SettingMenu li{
    text-align: left;
    padding: 0.5rem 0 0.5rem 1rem;
    /* border: solid 1px #f3f3f3; */
}
.SettingMenu li:hover{
    cursor: pointer;
}
.SettingMenu li a{
    color: var(--normalfont-color);
    font-size: 1rem;
    text-decoration: none;
}
.SettingMenu li:hover{
    background-color: var(--settings-bg);
}
.SettingMenu ul{
    margin-bottom: 0;
}
.highlighted{
    border-left: 5px solid var(--primary-accent)!important;
    background-color: var(--settings-bg);
    padding-left: 11px!important;
}
.main_content{
    width: auto;
	margin-left: 4rem;
	padding-top: 4.5rem;
    display: flex;
    flex-direction: row;
    overflow-x: auto;
}
.settingsOptionComponent{
    position: relative;
    top: 2rem;
    background-color: var(--default-bg);
    border-radius: 0.3rem;
    margin-left: 2rem;
    height: calc(100vh - 8.5rem);
    width: calc(100vw - 21rem);
    margin-right: 2rem;
}
