@import "allColors.css";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
html {
	box-sizing: border-box;
	font-size: 16px;
	font-family: "Poppins", sans-serif;
}
.outerArea1 {
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 20;
	background-color: transparent;
	/* border: solid 2px red; */
	height: 4.5rem;
	background: var(--default-bg);
	-webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
	-moz-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
	display: flex;
	flex-direction: row;
	align-items: center;
}
.logoArea {
	width: 4rem;
	position: relative;
	left: 0;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.infoArea {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	height: 100%;
}
.userArea {
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 100%;
	justify-content: space-evenly;
}
#dropDownArrow {
	margin-left: 0.5rem;
	margin-right: 0.5rem;
}
#dropDownArrow:hover * {
	stroke: var(--normalfont-color);
}
.dropdown-menu {
	margin-top: 1.8rem !important;
	background-color: var(--default-bg) !important;
}
.dropdown-menu a {
	text-decoration: none;
	color: #8a8a8a;
	padding-left: 1rem;
	font-size: 1rem;
}
.dropdown-menu a:hover {
	text-decoration: none;
	color: var(--normalfont-color);
	background-color: var(--settings-bg);
}
.allButtons {
	height: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
}
.buttonArea,
.titleArea {
	margin-left: 1rem;
	height: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: 0 0.6rem 0 0.6rem;
}
.titleArea {
	border-bottom: 5px solid var(--primary-accent);
}
.titleArea span {
	color: var(--normalfont-color);
	transform: translateY(3px);
	font-weight: 700 !important;
	font-size: 1.1rem;
}
.buttonArea a {
	text-decoration: none;
	color: #8a8a8a;
	font-weight: 700 !important;
	font-size: 1.1rem;
}
.buttonArea:hover a {
	text-decoration: none;
	color: var(--normalfont-color);
}
.companyArea {
	padding-right: 1rem;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: center;
}
.companyName {
	color: var(--normalfont-color);
	font-size: 1rem;
	font-weight: 600;
}
.subText {
	color: #8a8a8a;
	font-size: 1rem;
	font-weight: 500;
	margin-top: -5px;
}
.dropdown-item:active,
.dropdown-item:focus {
	background-color: var(--reacttable-odd) !important;
}
.profileLogo * {
	stroke: var(--normalfont-color);
}