@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{
    font-family: 'Poppins', sans-serif;
}
html{
    font-size: 16px;
}
#loginPage_root__1IuJf{
    overflow: hidden;
    height: 100%;
}
.loginPage_App__1Sfu9{
    height: 100%;
}
.loginPage_parentContainer__1Ij3k{
    overflow: hidden;
    width: 100%;
    height: 100vh;
    /* border: solid 2px red; */
    display: flex;
    flex-direction: row;
    outline: none;
}
.loginPage_formContainer__2ck6u{
    background-color: var(--default-bg);
    height: 100%;
    /* border: solid 3px purple; */
    justify-self: flex-end;
    width: 36%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
.loginPage_bgimgContainer__2nOak{
    width: 64%;
    background-image: url(/static/media/bg-login.f35cd6de.jpg);
    background-size: cover;
    background-position: -5rem;
}
.loginPage_innerContainer2__1yfUh{
    /* background-color: antiquewhite; */
    /* border: 2px solid royalblue; */
    padding: 1rem;
    /* font-weight: 700; */
    color: var(--normalfont-color);
    height: 80vh;
}
input[type='radio']{
    /* opacity: 0; */
    display: none;
}
.loginPage_loginLabel__2ALS5{
    font-size: 2rem;
    padding: 0 0.5rem 0 0.5rem;
    color: #a3a3a3;
    border-radius: 0.2rem;
    cursor: default;
}
.loginPage_loginLabel__2ALS5:hover{
    /* background-color: #ffe6efbd; */
    color: #5c5c5c;
}
.loginPage_innerContainer__3YxoS{
    margin-top: 10vh;
    /* border: 1px solid black; */
    height: 100%;
    width: 80%;
}
input[type='radio']:checked + label{
    color:var(--normalfont-color);
    font-weight: 600;
    border-radius: 0;
    border-bottom: 3px solid #ff1e70;
}
input[type='radio']:not(:checked) + label{
    cursor: pointer;
}
.loginPage_switch__2zv9i{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.loginPage_loginInputLabel__16bFJ{
    margin-top: 5vh;
    font-size: 1rem;
    color: #a3a3a3;
    font-weight: 600;
}
.loginPage_innerContainer2__1yfUh input{
    background: transparent;
    border-bottom: solid 2px #a3a3a3;
    width: 100%;
    padding: 0.6rem;
    padding-left: 0;
    font-size: 1.2rem;
    color: var(--normalfont-color);
}
.loginPage_innerContainer2__1yfUh input:focus{
    border-color: #ff1e70!important;
}
.loginPage_innerContainer2__1yfUh input::-webkit-input-placeholder{
    color: #a3a3a3;
    font-size: 1.2rem;
}
.loginPage_innerContainer2__1yfUh input:-ms-input-placeholder{
    color: #a3a3a3;
    font-size: 1.2rem;
}
.loginPage_innerContainer2__1yfUh input::-ms-input-placeholder{
    color: #a3a3a3;
    font-size: 1.2rem;
}
.loginPage_innerContainer2__1yfUh input::placeholder{
    color: #a3a3a3;
    font-size: 1.2rem;
}
.loginPage_primarybtn__2kgIt{
    color: white;
    border-radius: 0.2rem;
    padding: 0.4rem 1rem 0.4rem 1rem;
    font-weight: 600;
    font-size: 1.2rem;
    margin-top: 4rem;
    border: none!important;
    background-color: #ff1e70;
  }
  .loginPage_primarybtn__2kgIt:hover{
    background-color: #ff3d84;
    border: none;
  }
  .loginPage_primarybtn__2kgIt:active, .loginPage_primarybtn__2kgIt:focus{
    background-color: #e90055;
  }
  .loginPage_normalbtn__bitT1{
    color: var(--normalfont-color);
    border-radius: 0.2rem;
    padding: 0.4rem 1rem 0.4rem 1rem;
    font-size: 1.2rem;
    border: none!important;
    margin-top: 4.2rem;
    background-color: transparent;
  }
  .loginPage_normalbtn__bitT1:hover{
    /* border: 2.5px solid #ff1e70 ; */
    color: #ff1e70;
    border: none;
  }
  .loginPage_normalbtn__bitT1:active, .loginPage_normalbtn__bitT1:focus{
    color: #e90055;
  }
.loginPage_buttoncont__tysqM{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
  /* input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  } */

  /* Firefox */
  /* input[type=number] {
    -moz-appearance: textfield;
  } */
.loginPage_innerContainer3__3S25X{
    width: 100%;
    height: 100%;
}
:root{
    --primary-normal:#9b41d6;
    --font-family: 'Poppins', sans-serif;
    --edit-normal:#00c400;
    --edit-hover:#00df00;
    --edit-active:#00a000;
    --delete-normal:red;
    --delete-hover:#ff3e3e;
    --delete-active:#bd0000;

    --default-bg:#ffffff;
    --body-bg:#e6e6e6;
    --settings-bg:#f3f3f3;
    --reacttable-input:#ffffff;
    --reactcalendar-button-hover:#e6e6e6;


    --primary-accent:#9500ff;
    --primary-active:#8100dd;
    --primary-hover:#a322ff;
    --reacttable-odd:#e0b5ff59;
    --reacttable-hover:#bf64ff59;
    --reactcalendar-now:#fad2ff;
    --reactcalendar-now-hover:#f4bfff;
    --appearance-subcard-bg:#e3e3e3;
    --mainfont-color:#ffffff;

    --normalfont-color:#000000;

    --tableborder-color:#dbdbdb
}
* {
	margin: 0px;
	padding: 0px;
	box-sizing: border-box;
}
input,
select {
	outline: none;
	border: none;
}
a {
	outline: none;
}
/* input[type="number"] {
	-moz-appearance: textfield;
	appearance: none;
	-webkit-appearance: none;
} */
h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0px;
}

p {
	font-family: Poppins-Regular;
	font-size: 14px;
	line-height: 1.7;
	color: #666666;
	margin: 0px;
}

ul,
li {
	margin: 0px;
	list-style-type: none;
}
/* input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
	-webkit-appearance: none;
} */

button {
	outline: none !important;
	border: none;
	background: transparent;
}

button:hover {
	cursor: pointer;
}
body,
html {
	height: 100%;
	font-family: Poppins-Regular, sans-serif;
}
html {
	box-sizing: border-box;
	font-size: 16px;
	font-family: "Poppins", sans-serif;
	background-color: var(--body-bg);
}
body {
	background: var(--body-bg) !important;
	color: var(--normalfont-color);
	overflow-x: hidden;
}
* {
	font-family: "Poppins", sans-serif;
}

/* Main Content */

.mapContainer {
	padding: 1rem;
}
.pad {
	margin: 1rem !important;
	background-color: var(--default-bg);
	padding-bottom: 2rem;
}
.wrapper .main_content {
	width: auto;
	margin-left: 4rem;
	padding-top: 4.5rem;
}

.wrapper .main_content .header {
	padding: 20px;
	background: var(--default-bg);
	color: #717171;
	border-bottom: 1px solid #e0e4e8;
}

.wrapper .main_content .info {
	margin: 20px;
	color: #717171;
	line-height: 25px;
}
/* .wrapper.text-center{
  background-color: #f6f6f6;
} */
.wrapper .main_content .info div {
	margin-bottom: 20px;
}
.downloadCsvButton {
	margin-right: 1rem;
	border-radius: 0.3rem;
	padding: 0.6rem 1rem 0.6rem 1rem;
	border: solid 3px var(--normalfont-color);
	font-weight: bold;
	color: var(--normalfont-color);
}
.downloadCsvButton:hover {
	border-color: var(--primary-hover);
	color: var(--primary-hover);
}
.buttonContainer {
	border-radius: 0.3rem;
	padding: 0.6rem 1rem 0.6rem 1rem;
	background-color: var(--primary-accent) !important;
	display: flex;
	flex-direction: row;
	align-items: center;
	border: solid 3px var(--primary-accent);
	cursor: pointer;
}
.buttonContainerDisabled {
	border-radius: 0.3rem;
	padding: 0.6rem 1rem 0.6rem 1rem;
	background-color: var(--primary-accent) !important;
	display: flex;
	flex-direction: row;
	align-items: center;
	border: solid 3px var(--primary-accent);
	cursor: pointer;
	opacity: 0.5;
	cursor: default;
}
.buttonContainer:hover {
	background-color: var(--primary-hover) !important;
	border-color: var(--primary-hover);
}
.buttonContainer:active {
	background-color: var(--primary-active) !important;
	border-color: var(--primary-active);
}
.buttonText {
	text-decoration: none;
	color: var(--mainfont-color);
	font-weight: bold;
	justify-self: flex-end;
	font-family: "Poppins", sans-serif;
}
.buttonText:hover {
	text-decoration: none;
	color: var(--mainfont-color);
	font-weight: bold;
	justify-self: flex-end;
}
.header {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}
.welcomeContainer {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	font-family: var(--font-family);
	justify-content: flex-start;
}
.mainLine {
	color: var(--normalfont-color);
	font-family: var(--font-family);
	font-size: 1.2rem;
	font-weight: 600;
}
.subLine {
	color: gray;
	font-family: var(--font-family);
	font-size: 0.8rem;
}
.modal-content {
	background-color: var(--default-bg) !important;
	font-family: var(--font-family) !important;
}
.modal-title {
	color: var(--normalfont-color) !important;
	font-weight: 700;
}
.modal input {
	border: 1.2px solid black;
	border-radius: 0.3rem;
	background-color: var(--default-bg) !important;
	color: var(--normalfont-color) !important;
}
.modal input:focus {
	box-shadow: 0px 0px 2px 1px var(--primary-accent) !important;
	background-color: var(--default-bg) !important;
	color: var(--normalfont-color) !important;
}
.modal select {
	outline: none;
	border: 1.2px solid black;
	border-radius: 0.3rem;
	background-color: var(--default-bg) !important;
	color: var(--normalfont-color) !important;
}
.modal select:focus {
	box-shadow: 0px 0px 2px 1px var(--primary-accent) !important;
	background-color: var(--default-bg) !important;
	color: var(--normalfont-color) !important;
}
.main_content select {
	outline: none;
	border: 1.2px solid transparent;
	border-radius: 0.3rem;
	background-color: var(--default-bg) !important;
	color: var(--normalfont-color) !important;
	width: 8rem;
}
.main_content select:focus {
	box-shadow: 0px 0px 2px 1px var(--primary-accent) !important;
	background-color: var(--default-bg) !important;
	color: var(--normalfont-color) !important;
}
.modal-body {
	color: var(--normalfont-color) !important;
}
.close span {
	color: var(--normalfont-color) !important;
}
input[type="date"] {
	background-color: var(--default-bg);
	color: var(--normalfont-color);
}
input[type="date"]:focus,
input[type="date"]:active {
	background-color: var(--default-bg);
	color: var(--normalfont-color);
	box-shadow: 0px 0px 2px 1px var(--primary-accent);
}
.custom-file-label {
	color: var(--normalfont-color) !important;
	background-color: var(--default-bg) !important;
}
.custom-footer {
	justify-content: space-between !important;
}
.primary-btn {
	border: none !important;
	background-color: var(--primary-accent) !important;
	font-weight: bold !important;
	color: var(--mainfont-color) !important;
}
.primary-btn:hover {
	background-color: var(--primary-hover) !important;
	border: none !important;
}
.primary-btn:active,
.primary-btn:focus {
	border: none !important;
	background-color: var(--primary-active) !important;
}
.edit-btn {
	border: none !important;
	background-color: var(--edit-normal) !important;
	font-weight: bold !important;
}
.edit-btn:hover {
	background-color: var(--edit-hover) !important;
	border: none !important;
}
.edit-btn:active {
	border: none !important;
	background-color: var(--edit-active) !important;
}
.delete-btn {
	border: none !important;
	background-color: var(--delete-normal) !important;
	font-weight: bold !important;
}
.delete-btn:hover {
	background-color: var(--delete-hover) !important;
	border: none !important;
}
.delete-btn:active {
	border: none !important;
	background-color: var(--delete-active) !important;
}
.normal-btn {
	color: var(--normalfont-color) !important;
}
.normal-btn:hover {
	background-color: var(--reacttable-hover) !important;
	/* font-weight: bold!important; */
}
.normal-btn:active {
	background-color: var(--reacttable-hover) !important;
	/* font-weight: bold!important; */
}
.calendarContainer {
	margin-top: 1rem;
	padding: 1rem;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}
.dateLabel {
	margin-top: 1.5rem;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}
.table-btn {
	background-color: #c692e9;
	padding: 0.1rem 0.5rem 0.1rem 0.5rem;
	border-radius: 0.3rem;
	color: var(--mainfont-color);
}
.table-btn:hover {
	background-color: #ab5fdd;
}
.table-btn:active {
	background-color: #7b2bb1;
}
.font-weight-600 {
	font-weight: 600 !important;
	color: var(--normalfont-color);
}

hr {
	background-color: var(--normalfont-color) !important;
}
.extraClass {
	font-size: 1rem !important;
	pointer-events: auto !important;
	font-weight: bold;
}
.extraClass:hover {
	visibility: visible !important;
	opacity: 1 !important;
}
.driverDetailsButtons {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}
.timeLineContainer {
	width: 100%;
	margin-top: 3vh;
	margin-bottom: 5vh;
	background-color: var(--default-bg);
	height: auto;
	padding: 1rem;
}
.analyticsMapContainer {
	width: auto;
	margin: 3vh;
	background-color: transparent;
	height: auto;
	padding: 1rem;
}
.timeLineContainerHeader {
	background-color: var(--default-bg);
	text-align: left;
	margin-bottom: 1rem;
}
.loadingContainer {
	width: "100%";
	height: "100%";
	background: var(--default-bg);
	display: "flex";
	flex-direction: "row";
	justify-content: "center";
	align-items: "center";
	color: var(--normalfont-color);
}
.text-dark2 {
	color: var(--normalfont-color) !important;
}
/* .mapboxgl-map{
	position: absolute;
	left: 4rem!important;
	top: 4.5rem!important;
	border: solid 1rem transparent!important;
	width: 100%!important;
	height: 100%!important;
}
#deckgl-overlay{
	position: absolute;
	left: 4rem!important;
	top: 4.5rem!important;
	border: solid 1rem transparent!important;
	width: 80%!important;
	height: 90%!important;
} */
#deckgl-wrapper {
	position: absolute;
	left: 5rem !important;
	top: 7.5rem !important;
	border: solid 1rem var(--default-bg) !important;
	width: calc(100vw - 6rem) !important;
	padding-right: 2rem !important;
	/* margin-right: 1rem!important; */
	height: calc(100vh - 8.5rem) !important;
}
#control-panel {
	position: absolute;
	top: 8rem;
	right: 1.5rem;
	margin: 12px;
	padding: 20px;
	font-size: 12px;
	line-height: 1.5;
	z-index: 1;
	background: #fff;
	font-family: Helvetica, Arial, sans-serif;
	box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
	color: black;
}
#control-panel label {
	color: black;
}
#control-panel div {
	display: flex;
	flex-direction: column;
	width: 100%;
	justify-content: space-between;
	margin-bottom: 1.5rem;
}
.PhoneInputInput {
	padding: 0.4rem;
}

.analyticsInputPanel {
	margin-left: 1rem !important;
	width: calc(100vw - 6rem) !important;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding-top: 0.5rem;
}
.analyticsInputPanelInner {
	width: 30%;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	padding-top: 2px;
}
.checkBoxGroup {
	display: flex;
	flex-direction: row;
	align-items: center;
}
.checkBoxGroup label {
	margin-bottom: 0rem;
}
.slider {
	-webkit-appearance: none;
	width: 100%;
	height: 5px;
	border-radius: 5px;
	background: #d3d3d3;
	outline: none;
	opacity: 0.7;
	transition: opacity 0.2s;
	border-color: transparent;
}

.slider::-webkit-slider-thumb {
	-webkit-appearance: none;
	appearance: none;
	width: 15px;
	height: 15px;
	border-radius: 50%;
	background: var(--primary-accent);
	border-color: transparent;
	cursor: pointer;
}

.slider::-moz-range-thumb {
	width: 15px;
	height: 15px;
	border-radius: 50%;
	background: var(--primary-accent);
	border-color: transparent;
	cursor: pointer;
}
.containerCheckbox {
	display: block;
	position: relative;
	padding-left: 35px;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

/* Hide the browser's default checkbox */
.containerCheckbox input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

/* Create a custom checkbox */
.checkmark {
	position: absolute;
	top: -12.5px;
	left: 0;
	height: 25px;
	width: 25px;
	background-color: #eee;
	border-radius: 0.3rem;
}

/* On mouse-over, add a grey background color */
.containerCheckbox:hover input ~ .checkmark {
	background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.containerCheckbox input:checked ~ .checkmark {
	background-color: var(--primary-accent);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

/* Show the checkmark when checked */
.containerCheckbox input:checked ~ .checkmark:after {
	display: block;
	color: black;
}

/* Style the checkmark/indicator */
.containerCheckbox .checkmark:after {
	left: 10px;
	top: 6px;
	width: 5px;
	height: 10px;
	border: solid var(--mainfont-color);
	border-width: 0 3px 3px 0;
	transform: rotate(45deg);
}
.fileNameContainer {
	display: flex;
	flex-direction: row;
	width: 12rem;
	justify-content: space-between;
	align-items: center;
}
.fileNameContainer svg:hover .path1 {
	fill: var(--primary-accent);
	cursor: pointer;
}
.fileNameContainer svg .path2 {
	fill: var(--default-bg);
}
.__react_component_tooltip {
	margin-top: 0.7rem !important;
}
.error {
	color: red !important;
	font-size: 0.9rem;
}
.error404Container {
	background-color: var(--default-bg);
	width: calc(100vw - 2rem);
	height: calc(100vh - 2rem);
	position: absolute;
	top: 1rem;
	left: 1rem;
	background-image: url(/static/media/g10404.bf0294ca.svg);
	background-repeat: no-repeat;
	background-position: 103%;
	background-size: contain;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
}
.error404Container2 {
	width: 45%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	padding-left: 2rem;
	padding-top: 10vh;
}
.error404Container .error404Container2 a {
	text-decoration: none;
}
.error404Container .error404Container2 a:hover {
	text-decoration: none;
}
#logo1 {
	position: relative;
	left: 0.5rem;
	bottom: 0rem;
}
#logo2 {
	position: relative;
	bottom: 1.7rem;
	left: 3.5rem;
}
.tableContainer {
	margin-top: 1rem;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	height: 85%;
	overflow: auto;
	margin-bottom: 1rem;
}
.tableContainer table {
	width: 95%;
	overflow-x: scroll;
	border-collapse: collapse;
	background-color: var(--default-bg);
	color: var(--normalfont-color);
}
.tableContainer thead {
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.05);
}
.tableContainer thead th {
	text-align: center;
	background-color: var(--default-bg);
	padding: 0.5rem;
}
.emptyTh {
	width: 10%;
}
.thirdRow {
	border-bottom: solid 1px var(--tableborder-color);
}
tr td,
tr th {
	border-left: 1px solid var(--tableborder-color);
	border-top: 1px solid var(--tableborder-color);
	padding: 0.5rem;
}
tr td:last-of-type {
	border-right: 1px solid var(--tableborder-color);
}
tr td input[type="number"] {
	width: 100%;
	text-align: center;
	background-color: var(--default-bg);
	color: var(--normalfont-color);
}
.pad2 {
	margin: 1rem !important;
	background-color: var(--default-bg);
	padding-bottom: 2rem;
	height: calc(100vh - 6.5rem);
}
.a1 {
	border: solid 3px red;
	border-bottom: 0;
}
.a {
	border: solid 1px rgb(0, 212, 0);
	border-bottom: 0;
}
.b1 {
	border: solid 3px red;
	border-bottom: solid 1px var(--tableborder-color);
	border-top: solid 1px var(--tableborder-color);
}
.c1 {
	border: solid 3px red;
	border-top: solid 1px var(--tableborder-color);
}
.dropzone {
	text-align: center;
	padding: 20px;
	border: 2px solid var(--settings-bg);
	background-color: var(--settings-bg);
	border-radius: 0.3rem;
	cursor: pointer;
}
.dropzone p {
	color: var(--normalfont-color) !important;
	font-family: var(--font-family);
}
.dragActive {
	box-shadow: 0px 0px 2px 1px var(--primary-accent) !important;
}
/* .accept{
	background-color: #1eff0077;
}
.reject{
	background-color: #ff000077;
} */
.dropzone .error {
	color: #ff0000 !important;
	font-size: 0.8rem;
	font-weight: 600;
}
.dropzone .success {
	color: #00be00 !important;
	font-size: 0.8rem;
	font-weight: 600;
}
.loader {
	margin: 0 0 2em;
	height: 100px;
	width: 30%;
	text-align: center;
	padding: 1em;
	margin: 0 auto 1em;
	display: flex;
	flex-direction: row;
	justify-content: center;
	vertical-align: top;
	margin-bottom: 1.5rem;
}
.loaderContainer {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 30vw;
	height: 30vh;
	background-color: var(--default-bg);
	opacity: 0.85;
	/* background-color: rgba(255, 255, 255, 0.822); */
	box-shadow: 0px 0px 14px 3px rgba(0, 0, 0, 0.19) !important;
	z-index: 100 !important;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.loader svg path,
.loader svg rect {
	fill: var(--primary-accent);
}
.loaderContainerText {
	padding-right: 0.7rem;
	/* color: black; */
	color: var(--normalfont-color);
}

.sideMapContainer {
	padding-left: 16rem;
	position: relative;
	top: 1rem;
	background-color: var(--default-bg);
	border-radius: 0.3rem;
	margin-left: 1rem;
	height: calc(100vh - 6.5rem);
	width: calc(100vw - 4rem);
	margin-right: 1rem;
}
.wrapperSideMenu {
	position: absolute;
	background-color: var(--default-bg);
	top: 1rem;
	width: 16rem;
	left: 1rem;
	border-radius: 0.3rem;
	height: calc(100vh - 8.5rem);
}
.sideMenu {
	margin-right: 1rem;
	height: 100%;
	padding: 1rem 0 0rem 0rem;
	text-align: left;
	color: var(--normalfont-color);
	display: flex;
	flex-direction: column;
}
.sideMenuHeading {
	padding-left: 0.8rem;
	font-weight: bold;
	font-size: 1.2rem;
	background-color: var(--default-bg);
	width: 100%;
	box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.05);
}
.sideMenuList{
	position: relative;
	padding-top: 1rem;
	overflow-y: auto;
	height: auto;
}
.sideMenuList li{
	padding: 0.7rem 0 0.7rem 1rem;
	border-bottom: 1px solid var(--settings-bg);
}
.sideMenuList li:hover{
	cursor: pointer;
    background-color: var(--settings-bg);
}
.li_active{
    border-left: 5px solid var(--primary-accent)!important;
    background-color: var(--settings-bg);
    padding-left: 11px!important;
}
.li_highlighted{
    background-color: var(--settings-bg);
}
/* .li_highlighted i{
	color:var(--primary-accent)
} */
li i{
	color: #a1a1a1;
}
.li_highlighted i{
	color:var(--primary-accent)
}
.li_active i{
	color:var(--primary-accent)
}
.menuSubline{
	/* margin-left: 1rem; */
	color: #a1a1a1;
	font-size: 0.8rem;
	font-weight: normal;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}
.menuSubline2{
	/* margin-left: 1rem; */
	color: #a1a1a1;
	font-size: 0.8rem;
	font-weight: normal;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
}
.menuSubline2 div{
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}
.menuSubline2 div span{
	font-weight: 600;
}
.menuSubline div:last-of-type{
	cursor: text;
	margin-right: 0.5rem;
}
.menuSubline i{
	color: #a1a1a1!important;
}
.sideMenuHeading input[type="text"] {
	margin-top: 0.7rem;
	padding: 0.4rem;
	width: 100%;
	background-color: var(--default-bg);
	border: 0.2px solid #a1a1a1;
	color: var(--normalfont-color);
	border-radius: 0.3rem;
	font-size: 0.9rem;
}
.sideMenuHeading input[type="text"]:focus,
.sideMenuHeading input[type="text"]:active {
	background-color: var(--default-bg);
	color: var(--normalfont-color);
	box-shadow: 0px 0px 2px 1px var(--primary-accent);
}
.main_content .sideMapContainer2 {
	padding-left: 16rem;
	position: relative;
	top: 1rem;
	background-color: var(--default-bg);
	border-radius: 0.3rem;
	margin-left: 1rem;
	height: calc(100vh - 8.5rem);
	width: calc(100vw - 6rem);
	margin-right: 1rem;
}
.main_content .wrapperSideMenu2 {
	position: absolute;
	background-color: var(--default-bg);
	top: 1rem;
	width: 16rem;
	left: 1rem;
	border-radius: 0.3rem;
	height: calc(100vh - 10.5rem);
}
/* .*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  font-family: 'Josefin Sans', sans-serif;
} */

/* Sidebar Navigation Bar */
html{
  font-size: 16px;
}
.wrapperNavbar .sidebar{
  width: 4rem;
  height: 100%;
  background:var(--default-bg);
  padding: 30px 0px;
  position: fixed;
  z-index: 10;
  left: 0%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.wrapperNavbar .sidebar h2{
  color: var(--normalfont-color);
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 30px;
}

.wrapperNavbar .sidebar ul{
  /* padding: 15px; */
  /* border-bottom: 1px solid #bdb8d7; */
  /* border-bottom: 1px solid rgba(0,0,0,0.05); */
  /* border-top: 1px solid rgba(255,255,255,0.05); */
  margin-top: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 80vh;
  justify-content: center;
}
.wrapperNavbar .sidebar ul li{
  /* padding: 15px; */
  /* border-bottom: 1px solid #bdb8d7; */
  /* border-bottom: 1px solid rgba(0,0,0,0.05); */
  /* border-top: 1px solid rgba(255,255,255,0.05); */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.wrapperNavbar .sidebar ul li a{
  margin-top: 2vh;
  color: var(--normalfont-color);
  display: block;
  text-align: left;
  text-decoration: none;
  border: transparent solid 3px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 5px;
  width: 3rem;
  height: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.wrapperNavbar .sidebar ul li a .fas{
  width: 25px;
  }

.wrapperNavbar .sidebar ul li:hover{
  background-color:var(--default-bg);
}

.wrapperNavbar .sidebar ul li:hover a{
  color: var(--mainfont-color);
  text-decoration: none;
  font-weight: bold;
  border: solid 3px var(--primary-accent);
}
.wrapperNavbar .sidebar ul li:hover a svg *{
  fill: var(--primary-accent);
}
.highlighted a{
  border: solid 3px var(--primary-accent)!important;
}
.highlighted a svg *{
  fill: var(--primary-accent);
}
.innerList1{
  display: none;
  position: absolute;
  left: 100%;
  /* top: ; */
  width: 100%;
  top: 16.45%;
  background-color:var(--primary-hover);
  opacity: 1;
}
.innerList2{
  display: none;
  position: absolute;
  left: 100%;
  /* top: ; */
  width: 100%;
  top: 40%;
  background-color:var(--primary-hover);
  opacity: 1;
}
.wrapperNavbar .sidebar ul li:nth-child(2):hover .innerList1{
  display: block;
}
.wrapperNavbar .sidebar ul li:nth-child(6):hover .innerList2{
  display: block;
}
/* .wrapperNavbar .sidebar ul li:hover > ul{
  opacity: 1;
  visibility: visible;
  left:0%;
} */
/* .cont1{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  background-color: red;
} */
#zaltFooter{
  position: fixed;
  bottom: 2vh
}
html {
	box-sizing: border-box;
	font-size: 16px;
	font-family: "Poppins", sans-serif;
}
.outerArea1 {
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 20;
	background-color: transparent;
	/* border: solid 2px red; */
	height: 4.5rem;
	background: var(--default-bg);
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
	display: flex;
	flex-direction: row;
	align-items: center;
}
.logoArea {
	width: 4rem;
	position: relative;
	left: 0;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.infoArea {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	height: 100%;
}
.userArea {
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 100%;
	justify-content: space-evenly;
}
#dropDownArrow {
	margin-left: 0.5rem;
	margin-right: 0.5rem;
}
#dropDownArrow:hover * {
	stroke: var(--normalfont-color);
}
.dropdown-menu {
	margin-top: 1.8rem !important;
	background-color: var(--default-bg) !important;
}
.dropdown-menu a {
	text-decoration: none;
	color: #8a8a8a;
	padding-left: 1rem;
	font-size: 1rem;
}
.dropdown-menu a:hover {
	text-decoration: none;
	color: var(--normalfont-color);
	background-color: var(--settings-bg);
}
.allButtons {
	height: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
}
.buttonArea,
.titleArea {
	margin-left: 1rem;
	height: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: 0 0.6rem 0 0.6rem;
}
.titleArea {
	border-bottom: 5px solid var(--primary-accent);
}
.titleArea span {
	color: var(--normalfont-color);
	transform: translateY(3px);
	font-weight: 700 !important;
	font-size: 1.1rem;
}
.buttonArea a {
	text-decoration: none;
	color: #8a8a8a;
	font-weight: 700 !important;
	font-size: 1.1rem;
}
.buttonArea:hover a {
	text-decoration: none;
	color: var(--normalfont-color);
}
.companyArea {
	padding-right: 1rem;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: center;
}
.companyName {
	color: var(--normalfont-color);
	font-size: 1rem;
	font-weight: 600;
}
.subText {
	color: #8a8a8a;
	font-size: 1rem;
	font-weight: 500;
	margin-top: -5px;
}
.dropdown-item:active,
.dropdown-item:focus {
	background-color: var(--reacttable-odd) !important;
}
.profileLogo * {
	stroke: var(--normalfont-color);
}
.rtContainer {
	padding-top: 10vh;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}
.rtContainer2 {
	padding-top: 5vh;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}
.ReactTable {
	position: relative;
	display: flex;
	flex-direction: column;
	border: 1px solid rgba(0, 0, 0, 0.1);
	width: 80%;
	border-radius: 0.3rem;
	align-self: center;
	/* background-color: white; */
}
.-width1 {
	width: 90%;
}
.ReactTable * {
	box-sizing: border-box;
}

.ReactTable .rt-table {
	flex: auto 1;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	width: 100%;
	border-collapse: collapse;
	overflow: auto;
}

.ReactTable .rt-thead {
	flex: 1 0 auto;
	display: flex;
	flex-direction: column;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.ReactTable .rt-thead.-headerGroups {
	background: rgba(0, 0, 0, 0.03);
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.ReactTable .rt-thead.-filters {
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.ReactTable .rt-thead.-filters input,
.ReactTable .rt-thead.-filters select {
	border: 1px solid rgba(0, 0, 0, 0.1);
	background: var(--reacttable-input);
	padding: 5px 7px;
	font-size: inherit;
	border-radius: 3px;
	font-weight: normal;
	outline: none;
	color: var(--normalfont-color);
}

.ReactTable .rt-thead.-filters .rt-th {
	border-right: 1px solid rgba(0, 0, 0, 0.02);
}

.ReactTable .rt-thead.-header {
	box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);
}

.ReactTable .rt-thead .rt-tr {
	text-align: left;
	color: var(--mainfont-color);
	font-weight: 600;
	background-color: var(--primary-accent);
	padding: 1rem;
	border-radius: 0.3rem 0.3rem 0 0;
}
.ReactTable .rt-thead.-filters .rt-tr {
	text-align: left;
	color: var(--mainfont-color);
	font-weight: 600;
	background-color: var(--default-bg);
	/* background-color: white; */
	padding: 1rem;
	border-radius: 0rem 0rem 0 0;
}
.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
	padding: 5px 5px;
	line-height: normal;
	position: relative;
	border-right: 1px solid rgba(0, 0, 0, 0.05);
	transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
	box-shadow: inset 0 0 0 0 transparent;
}

.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
	box-shadow: inset 0 3px 0 0 #ffffff !important;
}

.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
	box-shadow: inset 0 -3px 0 0 #ffffff !important;
}

.ReactTable .rt-thead .rt-th.-cursor-pointer,
.ReactTable .rt-thead .rt-td.-cursor-pointer {
	cursor: pointer;
}

.ReactTable .rt-thead .rt-th:last-child,
.ReactTable .rt-thead .rt-td:last-child {
	border-right: 0;
}

.ReactTable .rt-thead .rt-resizable-header {
	overflow: visible;
}

.ReactTable .rt-thead .rt-resizable-header:last-child {
	overflow: hidden;
}

.ReactTable .rt-thead .rt-resizable-header-content {
	overflow: hidden;
	text-overflow: ellipsis;
}

.ReactTable .rt-thead .rt-header-pivot {
	border-right-color: #f7f7f7;
}

.ReactTable .rt-thead .rt-header-pivot:after,
.ReactTable .rt-thead .rt-header-pivot:before {
	left: 100%;
	top: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

.ReactTable .rt-thead .rt-header-pivot:after {
	border-color: rgb(255, 255, 255, 0);
	border-left-color: #fff;
	border-width: 8px;
	margin-top: -8px;
}

.ReactTable .rt-thead .rt-header-pivot:before {
	border-color: rgb(255, 255, 255);
	border-left-color: #f7f7f7;
	border-width: 10px;
	margin-top: -10px;
}

.ReactTable .rt-tbody {
	flex: 99999 1 auto;
	display: flex;
	flex-direction: column;
	overflow: auto;
	/* background-color: white; */
}

.ReactTable .rt-tbody .rt-tr-group {
	border-bottom: solid 1px rgba(0, 0, 0, 0.05);
}

.ReactTable .rt-tbody .rt-tr-group:last-child {
	border-bottom: 0;
}

.ReactTable .rt-tbody .rt-td {
	border-right: 1px solid rgba(0, 0, 0, 0.02);
	padding-left: 1.5rem;
	color: var(--normalfont-color);
}

.ReactTable .rt-tbody .rt-td:last-child {
	border-right: 0;
}

.ReactTable .rt-tbody .rt-expandable {
	cursor: pointer;
	text-overflow: clip;
}

.ReactTable .rt-tr-group {
	flex: 1 0 auto;
	display: flex;
	flex-direction: column;
	align-items: stretch;
}

.ReactTable .rt-tr {
	flex: 1 0 auto;
	display: inline-flex;
}

.ReactTable .rt-th,
.ReactTable .rt-td {
	flex: 1 0;
	white-space: nowrap;
	text-overflow: ellipsis;
	padding: 7px 5px;
	overflow: hidden;
	transition: 0.3s ease;
	transition-property: width, min-width, padding, opacity;
}

.ReactTable .rt-th.-hidden,
.ReactTable .rt-td.-hidden {
	width: 0 !important;
	min-width: 0 !important;
	padding: 0 !important;
	border: 0 !important;
	opacity: 0 !important;
}

.ReactTable .rt-expander {
	display: inline-block;
	position: relative;
	margin: 0;
	color: transparent;
	margin: 0 10px;
}

.ReactTable .rt-expander:after {
	content: "";
	position: absolute;
	width: 0;
	height: 0;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) rotate(-90deg);
	border-left: 5.04px solid transparent;
	border-right: 5.04px solid transparent;
	border-top: 7px solid rgba(0, 0, 0, 0.8);
	transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
	cursor: pointer;
}

.ReactTable .rt-expander.-open:after {
	transform: translate(-50%, -50%) rotate(0);
}

.ReactTable .rt-resizer {
	display: inline-block;
	position: absolute;
	width: 36px;
	top: 0;
	bottom: 0;
	/* right: -18px; */
	cursor: col-resize;
	z-index: 10;
	color: white;
}

.ReactTable .rt-tfoot {
	flex: 1 0 auto;
	display: flex;
	flex-direction: column;
	box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
}

.ReactTable .rt-tfoot .rt-td {
	border-right: 1px solid rgba(0, 0, 0, 0.05);
}

.ReactTable .rt-tfoot .rt-td:last-child {
	border-right: 0;
}

.ReactTable.-striped .rt-tr.-odd {
	background: var(--reacttable-odd)
}
.ReactTable.-striped .rt-tr.-even {
	background: var(--default-bg)
}
.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
	background: var(--reacttable-hover);
	cursor: pointer;
}

.ReactTable .-pagination {
	z-index: 1;
	display: flex;
	justify-content: space-between;
	align-items: stretch;
	flex-wrap: wrap;
	padding: 3px;
	box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
	border-top: 2px solid rgba(0, 0, 0, 0.1);
}

.ReactTable .-pagination input,
.ReactTable .-pagination select {
	border: 1px solid rgba(0, 0, 0, 0.1);
	background: var(--default-bg)!important;
	padding: 5px 7px;
	font-size: inherit;
	border-radius: 3px;
	font-weight: normal;
	outline: none;
	color: var(--normalfont-color);
}
.ReactTable .-pagination option {
	background-color: var(--default-bg);
	color: var(--normalfont-color);
}
.ReactTable .-pagination .-btn {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	display: block;
	width: 80%;
	height: 100%;
	border: 0;
	border-radius: 3px;
	padding: 6px;
	font-size: 1em;
	color: var(--normalfont-color);
	background: transparent;
	transition: all 0.1s ease;
	cursor: pointer;
	outline: none;
}
.ReactTable .-pagination .-btn[disabled] {
	opacity: .1;
	cursor: default;
	/* color: rgba(0, 0, 0, 0.6); */
}

.ReactTable .-pagination .-btn:not([disabled]):hover {
	background: var(--reacttable-hover);
	/* color: var(--normalfont-color); */
	color: var(--normalfont-color);
}

.ReactTable .-pagination .-previous {
	flex: 1 1;
	/* text-align:left; */
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
}
.ReactTable .-pagination .-next {
	flex: 1 1;
	/* text-align:right; */
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}
.ReactTable .-pagination .-center {
	flex: 1.5 1;
	text-align: center;
	margin-bottom: 0;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-around;
}

.ReactTable .-pagination .-pageInfo {
	display: inline-block;
	margin: 3px 10px;
	white-space: nowrap;
	color: var(--normalfont-color);
}

.ReactTable .-pagination .-pageJump {
	display: inline-block;
}

.ReactTable .-pagination .-pageJump input {
	width: 70px;
	text-align: center;
}

.ReactTable .-pagination .-pageSizeOptions {
	margin: 3px 10px;
}

.ReactTable .rt-noData {
	display: block;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	background: rgba(255, 255, 255, 0.8);
	transition: all 0.3s ease;
	z-index: 1;
	pointer-events: none;
	padding: 20px;
	color: rgba(0, 0, 0, 0.5);
}

.ReactTable .-loading {
	display: block;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: var(--default-bg);
	transition: all 0.3s ease;
	z-index: -1;
	opacity: 0;
	pointer-events: none;
}

.ReactTable .-loading > div {
	position: absolute;
	display: block;
	text-align: center;
	width: 100%;
	top: 50%;
	left: 0;
	font-size: 15px;
	color: var(--normalfont-color);
	transform: translateY(-52%);
	transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.ReactTable .-loading.-active {
	opacity: 0.9;
	z-index: 2;
	pointer-events: all;
}

.ReactTable .-loading.-active > div {
	transform: translateY(50%);
}

.ReactTable .rt-resizing .rt-th,
.ReactTable .rt-resizing .rt-td {
	transition: none !important;
	cursor: col-resize;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.react-calendar-timeline * {
	box-sizing: border-box;
}

.react-calendar-timeline .rct-outer {
	display: block;
	overflow: hidden;
	white-space: nowrap;
}

.react-calendar-timeline .rct-scroll {
	display: inline-block;
	white-space: normal;
	vertical-align: top;
	overflow-x: scroll;
	overflow-y: hidden;
	touch-action: none;
}

.react-calendar-timeline .rct-item:hover {
	z-index: 88;
}

.react-calendar-timeline .rct-item .rct-item-content {
	position: sticky;
	position: -webkit-sticky;
	left: 0px;
	overflow: hidden;
	display: inline-block;
	border-radius: 2px;
	padding: 0 6px 0 4px;
	height: 100%;
}

.react-calendar-timeline .rct-sidebar {
	overflow: hidden;
	white-space: normal;
	display: inline-block;
	vertical-align: top;
	position: relative;
	box-sizing: border-box;
	border-left: 1px solid #bbb;
	border-right: 1px solid #c5c5c5c5;
	border-top: 1px solid #bbb;
	border-bottom: 1px solid #bbb;
}
.react-calendar-timeline .rct-sidebar.rct-sidebar-right {
	border-right: 0;
	border-left: 1px solid #bbb;
}
.react-calendar-timeline .rct-sidebar .rct-sidebar-row {
	padding: 0 4px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	box-sizing: border-box;
	margin: 0;
	border-bottom: 1px solid #bbb;
	/* border-top: 1px solid #bbb; */
	/* border: none; */
	background-color: var(--default-bg);
	color: var(--normalfont-color);
}
/* .react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-odd {
	background: rgba(0, 0, 0, 0.05);
}
.react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-even {
	background: transparent;
} */

.react-calendar-timeline .rct-vertical-lines .rct-vl {
	position: absolute;
	border-left: 1px solid #c5c5c560;
	border-right: 1px solid #c5c5c560;
	z-index: 30;
}
.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-vl-first {
	border-left-width: 2px;
}
.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-6,
.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-0 {
	background: rgba(255, 255, 255, 0);
}

.react-calendar-timeline .rct-horizontal-lines {
	-webkit-user-select: none;
	-moz-user-select: -moz-none;
	-ms-user-select: none;
	user-select: none;
}
.react-calendar-timeline .rct-horizontal-lines .rct-hl-even,
.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
	border-bottom: 1px solid #bbb;
	/* border-right: 1px solid #bbb; */
	box-sizing: border-box;
	z-index: 40;
	background-color: var(--default-bg);
}
/* .react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
	background: rgba(0, 0, 0, 0.05);
}
.react-calendar-timeline .rct-horizontal-lines .rct-hl-even {
	background: transparent;
} */

.react-calendar-timeline .rct-cursor-line {
	position: absolute;
	width: 2px;
	background: #2196f3;
	z-index: 51;
}

.react-calendar-timeline .rct-dateHeader {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	border-bottom: 1px solid #bbb;
	cursor: pointer;
	font-size: 14px;
	background-color: var(--default-bg);
	border-left: 2px solid #bbb;
	color: var(--normalfont-color);
	font-weight: bold;
	border: none;
}

.react-calendar-timeline .rct-dateHeader-primary {
	background-color: transparent;
	background-color: initial;
	border-left: 1px solid #bbb;
	border-right: 1px solid #bbb;
	color: #8a8a8a;
	text-align: left;
	/* border: none; */
	/* display: none; */
}

.react-calendar-timeline .rct-header-root {
	background: var(--default-bg);
	border-bottom: none;
	color: var(--normalfont-color);
}

.react-calendar-timeline .rct-calendar-header {
	border-bottom: 1px solid #bbb;
}

.mapElement{
	/* padding-top: 4vh; */
	height: 100%;
	width: 83%;
	margin: auto;
	text-align: center;
	position: relative;
	border: solid 1rem var(--default-bg);
	/* left: 15%; */
}
.react-calendar {
  width: 350px;
  max-width: 100%;
  background: white;
  border: 1px solid #a0a096;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
  background-color: var(--default-bg);
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  height: 44px;
  margin-bottom: 1em;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: var(--reactcalendar-navbutton);
  color: var(--normalfont-color);
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: var(--reactcalendar-navbutton-hover);
}
.react-calendar__navigation button[disabled] {
  background-color: var(--reactcalendar-navbutton-disabled);
}
.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 0.75em 0.5em;
  background: none;
  color: var(--normalfont-color);
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
  color: var(--normalfont-color);
}
.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}
.react-calendar__month-view__days__day--weekend {
  color: var(--primary-accent);
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile:disabled {
  background-color: var(--reactcalendar-navbutton-disabled2);
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: var(--reactcalendar-navbutton-hover);
}
.react-calendar__tile--now {
  background: var(--reactcalendar-now);
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: var(--reactcalendar-now-hover);
}
.react-calendar__tile--hasActive {
  background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}
.react-calendar__tile--active {
  background: var(--primary-active);
  color: var(--mainfont-color);
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: var(--primary-accent);
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

:root{
    --primary-normal:#9b41d6;
    --font-family: 'Poppins', sans-serif;
    --edit-normal:#00c400;
    --edit-hover:#00df00;
    --edit-active:#00a000;
    --delete-normal:red;
    --delete-hover:#ff3e3e;
    --delete-active:#bd0000;

    --default-bg:#ffffff;
    --body-bg:#e6e6e6;
    --settings-bg:#f3f3f3;
    --reacttable-input:#ffffff;
    --reactcalendar-button-hover:#e6e6e6;


    --primary-accent:#9500ff;
    --primary-active:#8100dd;
    --primary-hover:#a322ff;
    --reacttable-odd:#e0b5ff59;
    --reacttable-hover:#bf64ff59;
    --reactcalendar-now:#fad2ff;
    --reactcalendar-now-hover:#f4bfff;
    --appearance-subcard-bg:#e3e3e3;
    --mainfont-color:#ffffff;

    --normalfont-color:#000000;
}
* {
	margin: 0px;
	padding: 0px;
	box-sizing: border-box;
}
input,
select {
	outline: none;
	border: none;
}
a {
	outline: none;
}
/* input[type="number"] {
	-moz-appearance: textfield;
	appearance: none;
	-webkit-appearance: none;
} */
h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0px;
}

p {
	font-family: Poppins-Regular;
	font-size: 14px;
	line-height: 1.7;
	color: #666666;
	margin: 0px;
}

ul,
li {
	margin: 0px;
	list-style-type: none;
}
/* input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
	-webkit-appearance: none;
} */

button {
	outline: none !important;
	border: none;
	background: transparent;
}

button:hover {
	cursor: pointer;
}
body,
html {
	height: 100%;
	font-family: Poppins-Regular, sans-serif;
}
html {
	box-sizing: border-box;
	font-size: 16px;
	font-family: "Poppins", sans-serif;
	background-color: var(--body-bg);
}
body {
	background: var(--body-bg) !important;
	color: var(--normalfont-color);
	overflow-x: hidden;
}
* {
	font-family: "Poppins", sans-serif;
}

/* Main Content */

.mains_mapContainer__1ir9u {
	padding: 1rem;
}
.mains_pad__sOEfA {
	margin: 1rem !important;
	background-color: var(--default-bg);
	padding-bottom: 2rem;
}
.mains_wrapper__3l08d .mains_main_content__3dEMW {
	width: auto;
	margin-left: 4rem;
	padding-top: 4.5rem;
}

.mains_wrapper__3l08d .mains_main_content__3dEMW .mains_header__3pt4a {
	padding: 20px;
	background: var(--default-bg);
	color: #717171;
	border-bottom: 1px solid #e0e4e8;
}

.mains_wrapper__3l08d .mains_main_content__3dEMW .mains_info__21Vai {
	margin: 20px;
	color: #717171;
	line-height: 25px;
}
/* .wrapper.text-center{
  background-color: #f6f6f6;
} */
.mains_wrapper__3l08d .mains_main_content__3dEMW .mains_info__21Vai div {
	margin-bottom: 20px;
}
.mains_downloadCsvButton__31g63 {
	margin-right: 1rem;
	border-radius: 0.3rem;
	padding: 0.6rem 1rem 0.6rem 1rem;
	border: solid 3px var(--normalfont-color);
	font-weight: bold;
	color: var(--normalfont-color);
}
.mains_downloadCsvButton__31g63:hover {
	border-color: var(--primary-hover);
	color: var(--primary-hover);
}
.mains_buttonContainer__22THY {
	border-radius: 0.3rem;
	padding: 0.6rem 1rem 0.6rem 1rem;
	background-color: var(--primary-accent) !important;
	display: flex;
	flex-direction: row;
	align-items: center;
	border: solid 3px var(--primary-accent);
	cursor: pointer;
}
.mains_buttonContainer__22THY:hover {
	background-color: var(--primary-hover) !important;
	border-color: var(--primary-hover);
}
.mains_buttonContainer__22THY:active {
	background-color: var(--primary-active) !important;
	border-color: var(--primary-active);
}
.mains_buttonText__2kzKt {
	text-decoration: none;
	color: var(--mainfont-color);
	font-weight: bold;
	justify-self: flex-end;
	font-family: "Poppins", sans-serif;
}
.mains_buttonText__2kzKt:hover {
	text-decoration: none;
	color: var(--mainfont-color);
	font-weight: bold;
	justify-self: flex-end;
}
.mains_header__3pt4a {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}
.mains_welcomeContainer__T6XWZ {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	font-family: var(--font-family);
	justify-content: flex-start;
}
.mains_mainLine__PvoyX {
	color: var(--normalfont-color);
	font-family: var(--font-family);
	font-size: 1.2rem;
	font-weight: 600;
}
.mains_subLine__-lhYw {
	color: gray;
	font-family: var(--font-family);
	font-size: 0.8rem;
}
.mains_modal-content__uuo0z {
	background-color: var(--default-bg) !important;
	font-family: var(--font-family) !important;
}
.mains_modal-title__21Mnx {
	color: var(--normalfont-color) !important;
	font-weight: 700;
}
.mains_modal__2tvJC input {
	border: 1.2px solid black;
	border-radius: 0.3rem;
	background-color: var(--default-bg) !important;
	color: var(--normalfont-color) !important;
}
.mains_modal__2tvJC input:focus {
	box-shadow: 0px 0px 2px 1px var(--primary-accent) !important;
	background-color: var(--default-bg) !important;
	color: var(--normalfont-color) !important;
}
.mains_modal__2tvJC select {
	outline: none;
	border: 1.2px solid black;
	border-radius: 0.3rem;
	background-color: var(--default-bg) !important;
	color: var(--normalfont-color) !important;
}
.mains_modal__2tvJC select:focus {
	box-shadow: 0px 0px 2px 1px var(--primary-accent) !important;
	background-color: var(--default-bg) !important;
	color: var(--normalfont-color) !important;
}
.mains_main_content__3dEMW select {
	outline: none;
	border: 1.2px solid transparent;
	border-radius: 0.3rem;
	background-color: var(--default-bg) !important;
	color: var(--normalfont-color) !important;
	width: 8rem;
}
.mains_main_content__3dEMW select:focus {
	box-shadow: 0px 0px 2px 1px var(--primary-accent) !important;
	background-color: var(--default-bg) !important;
	color: var(--normalfont-color) !important;
}
.mains_modal-body__AYpDh {
	color: var(--normalfont-color) !important;
}
.mains_close__--KqL span {
	color: var(--normalfont-color) !important;
}
input[type="date"] {
	background-color: var(--default-bg);
	color: var(--normalfont-color);
}
input[type="date"]:focus,
input[type="date"]:active {
	background-color: var(--default-bg);
	color: var(--normalfont-color);
	box-shadow: 0px 0px 2px 1px var(--primary-accent);
}
.mains_custom-file-label__2BPPU {
	color: var(--normalfont-color) !important;
	background-color: var(--default-bg) !important;
}
.mains_custom-footer__3MoWd {
	justify-content: space-between !important;
}
.mains_primary-btn__3Zfev {
	border: none !important;
	background-color: var(--primary-accent) !important;
	font-weight: bold !important;
	color: var(--mainfont-color) !important;
}
.mains_primary-btn__3Zfev:hover {
	background-color: var(--primary-hover) !important;
	border: none !important;
}
.mains_primary-btn__3Zfev:active,
.mains_primary-btn__3Zfev:focus {
	border: none !important;
	background-color: var(--primary-active) !important;
}
.mains_edit-btn__1n690 {
	border: none !important;
	background-color: var(--edit-normal) !important;
	font-weight: bold !important;
}
.mains_edit-btn__1n690:hover {
	background-color: var(--edit-hover) !important;
	border: none !important;
}
.mains_edit-btn__1n690:active {
	border: none !important;
	background-color: var(--edit-active) !important;
}
.mains_delete-btn__3fgsZ {
	border: none !important;
	background-color: var(--delete-normal) !important;
	font-weight: bold !important;
}
.mains_delete-btn__3fgsZ:hover {
	background-color: var(--delete-hover) !important;
	border: none !important;
}
.mains_delete-btn__3fgsZ:active {
	border: none !important;
	background-color: var(--delete-active) !important;
}
.mains_normal-btn__3_8e9 {
	color: var(--normalfont-color) !important;
}
.mains_normal-btn__3_8e9:hover {
	background-color: var(--reacttable-hover) !important;
	/* font-weight: bold!important; */
}
.mains_normal-btn__3_8e9:active {
	background-color: var(--reacttable-hover) !important;
	/* font-weight: bold!important; */
}
.mains_calendarContainer__1sq5X {
	margin-top: 1rem;
	padding: 1rem;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}
.mains_dateLabel__2uUWj {
	margin-top: 1.5rem;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}
.mains_table-btn__20r9y {
	background-color: #c692e9;
	padding: 0.1rem 0.5rem 0.1rem 0.5rem;
	border-radius: 0.3rem;
	color: var(--mainfont-color);
}
.mains_table-btn__20r9y:hover {
	background-color: #ab5fdd;
}
.mains_table-btn__20r9y:active {
	background-color: #7b2bb1;
}
.mains_font-weight-600__E4sy3 {
	font-weight: 600 !important;
	color: var(--normalfont-color);
}

hr {
	background-color: var(--normalfont-color) !important;
}
.mains_extraClass__AQYYK {
	font-size: 1rem !important;
	pointer-events: auto !important;
	font-weight: bold;
}
.mains_extraClass__AQYYK:hover {
	visibility: visible !important;
	opacity: 1 !important;
}
.mains_driverDetailsButtons__2Hnn0 {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}
.mains_timeLineContainer__1DDTt {
	width: 100%;
	margin-top: 3vh;
	margin-bottom: 5vh;
	background-color: var(--default-bg);
	height: auto;
	padding: 1rem;
}
.mains_analyticsMapContainer__dEV_U {
	width: auto;
	margin: 3vh;
	background-color: transparent;
	height: auto;
	padding: 1rem;
}
.mains_timeLineContainerHeader__zhY8u {
	background-color: var(--default-bg);
	text-align: left;
	margin-bottom: 1rem;
}
.mains_loadingContainer__33kPO {
	width: "100%";
	height: "100%";
	background: var(--default-bg);
	display: "flex";
	flex-direction: "row";
	justify-content: "center";
	align-items: "center";
	color: var(--normalfont-color);
}
.mains_text-dark2__2LW10 {
	color: var(--normalfont-color) !important;
}
/* .mapboxgl-map{
	position: absolute;
	left: 4rem!important;
	top: 4.5rem!important;
	border: solid 1rem transparent!important;
	width: 100%!important;
	height: 100%!important;
}
#deckgl-overlay{
	position: absolute;
	left: 4rem!important;
	top: 4.5rem!important;
	border: solid 1rem transparent!important;
	width: 80%!important;
	height: 90%!important;
} */
#mains_deckgl-wrapper__2jANE {
	position: absolute;
	left: 5rem !important;
	top: 7.5rem !important;
	border: solid 1rem var(--default-bg) !important;
	width: calc(100vw - 6rem) !important;
	padding-right: 2rem !important;
	/* margin-right: 1rem!important; */
	height: calc(100vh - 8.5rem) !important;
}
#mains_control-panel__OVQ3R {
	position: absolute;
	top: 8rem;
	right: 1.5rem;
	margin: 12px;
	padding: 20px;
	font-size: 12px;
	line-height: 1.5;
	z-index: 1;
	background: #fff;
	font-family: Helvetica, Arial, sans-serif;
	box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
	color: black;
}
#mains_control-panel__OVQ3R label {
	color: black;
}
#mains_control-panel__OVQ3R div {
	display: flex;
	flex-direction: column;
	width: 100%;
	justify-content: space-between;
	margin-bottom: 1.5rem;
}
.mains_PhoneInputInput__2u7HV {
	padding: 0.4rem;
}
.mains_dropzone__25fH7 {
	text-align: center;
	padding: 20px;
	border: 2px dashed var(--normalfont-color);
	background-color: transparent;
	color: var(--normalfont-color) !important;
}
.mains_analyticsInputPanel__2WR1F {
	margin-left: 1rem !important;
	width: calc(100vw - 6rem) !important;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding-top: 0.5rem;
}
.mains_analyticsInputPanelInner__1YK2b {
	width: 30%;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	padding-top: 2px;
}
.mains_checkBoxGroup__1uJk6 {
	display: flex;
	flex-direction: row;
	align-items: center;
}
.mains_checkBoxGroup__1uJk6 label {
	margin-bottom: 0rem;
}
.mains_slider__friIa {
	-webkit-appearance: none;
	width: 100%;
	height: 5px;
	border-radius: 5px;
	background: #d3d3d3;
	outline: none;
	opacity: 0.7;
	transition: opacity 0.2s;
	border-color: transparent;
}

.mains_slider__friIa::-webkit-slider-thumb {
	-webkit-appearance: none;
	appearance: none;
	width: 15px;
	height: 15px;
	border-radius: 50%;
	background: var(--primary-accent);
	border-color: transparent;
	cursor: pointer;
}

.mains_slider__friIa::-moz-range-thumb {
	width: 15px;
	height: 15px;
	border-radius: 50%;
	background: var(--primary-accent);
	border-color: transparent;
	cursor: pointer;
}
.mains_containerCheckbox__20Ob1 {
	display: block;
	position: relative;
	padding-left: 35px;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

/* Hide the browser's default checkbox */
.mains_containerCheckbox__20Ob1 input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

/* Create a custom checkbox */
.mains_checkmark__CVgFU {
	position: absolute;
	top: -12.5px;
	left: 0;
	height: 25px;
	width: 25px;
	background-color: #eee;
	border-radius: 0.3rem;
}

/* On mouse-over, add a grey background color */
.mains_containerCheckbox__20Ob1:hover input ~ .mains_checkmark__CVgFU {
	background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.mains_containerCheckbox__20Ob1 input:checked ~ .mains_checkmark__CVgFU {
	background-color: var(--primary-accent);
}

/* Create the checkmark/indicator (hidden when not checked) */
.mains_checkmark__CVgFU:after {
	content: "";
	position: absolute;
	display: none;
}

/* Show the checkmark when checked */
.mains_containerCheckbox__20Ob1 input:checked ~ .mains_checkmark__CVgFU:after {
	display: block;
	color: black;
}

/* Style the checkmark/indicator */
.mains_containerCheckbox__20Ob1 .mains_checkmark__CVgFU:after {
	left: 10px;
	top: 6px;
	width: 5px;
	height: 10px;
	border: solid var(--mainfont-color);
	border-width: 0 3px 3px 0;
	transform: rotate(45deg);
}
.mains_fileNameContainer__1Akgp{
	display: flex;
	flex-direction: row;
	width: 12rem;
	justify-content: space-between;
	align-items: center;
}
.mains_fileNameContainer__1Akgp svg:hover .mains_path1__ENjfl{
	fill: var(--primary-accent);
	cursor: pointer;
}
.mains_fileNameContainer__1Akgp svg .mains_path2__UqCE3{
	fill: var(--default-bg);
}
.mains___react_component_tooltip__3OK2j{
	margin-top: 0.7rem!important;
}
.mains_error__2gK6c{
	color: red;
	font-size: 0.9rem;
}
/* .error404Container{
	background-color: var(--default-bg);
	width: calc(100vw - 2rem);
	height: calc(100vh - 2rem);
	position: absolute;
	top: 1rem;
	left: 1rem;
	background-image: url('../images/g10404.svg');
	background-repeat: no-repeat;
	background-position: 103%;
	background-size: contain;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
} */
.mains_error404Container2__1lJS8{
	width: 45%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	padding-left: 2rem;
	padding-top: 10vh;
}
.mains_error404Container__M7nZ2 .mains_error404Container2__1lJS8 a{
	text-decoration: none;
}
.mains_error404Container__M7nZ2 .mains_error404Container2__1lJS8 a:hover{
	text-decoration: none;
}
#mains_logo1__3BUYj{
	position: relative;
	left: 0.5rem;
	bottom: 0rem;
}
#mains_logo2__75gIg{
	position: relative;
	bottom: 1.7rem;
	left: 3.5rem;
}
/* .*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  font-family: 'Josefin Sans', sans-serif;
} */

/* Sidebar Navigation Bar */
html{
  font-size: 16px;
}
.navbar_wrapperNavbar__2o6Ew .navbar_sidebar__vziMf{
  width: 4rem;
  height: 100%;
  background:var(--default-bg);
  padding: 30px 0px;
  position: fixed;
  z-index: 10;
  left: 0%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.navbar_wrapperNavbar__2o6Ew .navbar_sidebar__vziMf h2{
  color: var(--normalfont-color);
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 30px;
}

.navbar_wrapperNavbar__2o6Ew .navbar_sidebar__vziMf ul{
  /* padding: 15px; */
  /* border-bottom: 1px solid #bdb8d7; */
  /* border-bottom: 1px solid rgba(0,0,0,0.05); */
  /* border-top: 1px solid rgba(255,255,255,0.05); */
  margin-top: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 80vh;
  justify-content: center;
}
.navbar_wrapperNavbar__2o6Ew .navbar_sidebar__vziMf ul li{
  /* padding: 15px; */
  /* border-bottom: 1px solid #bdb8d7; */
  /* border-bottom: 1px solid rgba(0,0,0,0.05); */
  /* border-top: 1px solid rgba(255,255,255,0.05); */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.navbar_wrapperNavbar__2o6Ew .navbar_sidebar__vziMf ul li a{
  margin-top: 2vh;
  color: var(--normalfont-color);
  display: block;
  text-align: left;
  text-decoration: none;
  border: transparent solid 3px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 5px;
  width: 3rem;
  height: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.navbar_wrapperNavbar__2o6Ew .navbar_sidebar__vziMf ul li a .navbar_fas__2-1o6{
  width: 25px;
  }

.navbar_wrapperNavbar__2o6Ew .navbar_sidebar__vziMf ul li:hover{
  background-color:var(--default-bg);
}

.navbar_wrapperNavbar__2o6Ew .navbar_sidebar__vziMf ul li:hover a{
  color: var(--mainfont-color);
  text-decoration: none;
  font-weight: bold;
  border: solid 3px var(--primary-accent);
}
.navbar_wrapperNavbar__2o6Ew .navbar_sidebar__vziMf ul li:hover a svg *{
  fill: var(--primary-accent);
}
.navbar_highlighted__3s6SX a{
  border: solid 3px var(--primary-accent)!important;
}
.navbar_highlighted__3s6SX a svg *{
  fill: var(--primary-accent);
}
.navbar_innerList1__13r0C{
  display: none;
  position: absolute;
  left: 100%;
  /* top: ; */
  width: 100%;
  top: 16.45%;
  background-color:var(--primary-hover);
  opacity: 1;
}
.navbar_innerList2__3degK{
  display: none;
  position: absolute;
  left: 100%;
  /* top: ; */
  width: 100%;
  top: 40%;
  background-color:var(--primary-hover);
  opacity: 1;
}
.navbar_wrapperNavbar__2o6Ew .navbar_sidebar__vziMf ul li:nth-child(2):hover .navbar_innerList1__13r0C{
  display: block;
}
.navbar_wrapperNavbar__2o6Ew .navbar_sidebar__vziMf ul li:nth-child(6):hover .navbar_innerList2__3degK{
  display: block;
}
/* .wrapperNavbar .sidebar ul li:hover > ul{
  opacity: 1;
  visibility: visible;
  left:0%;
} */
/* .cont1{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  background-color: red;
} */
#navbar_zaltFooter__2NBaQ{
  position: fixed;
  bottom: 2vh
}
.adminReact-table_rtContainer__34XDJ {
	padding-top: 10vh;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}
.adminReact-table_rtContainer2__8taAV {
	padding-top: 5vh;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}
.adminReact-table_ReactTable__xNjQl {
	position: relative;
	display: flex;
	flex-direction: column;
	border: 1px solid rgba(0, 0, 0, 0.1);
	width: 80%;
	border-radius: 0.3rem;
	align-self: center;
	/* background-color: white; */
}
.adminReact-table_-width1__2_FNc {
	width: 90%;
}
.adminReact-table_ReactTable__xNjQl * {
	box-sizing: border-box;
}

.adminReact-table_ReactTable__xNjQl .adminReact-table_rt-table__XuIKY {
	flex: auto 1;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	width: 100%;
	border-collapse: collapse;
	overflow: auto;
}

.adminReact-table_ReactTable__xNjQl .adminReact-table_rt-thead__1eE25 {
	flex: 1 0 auto;
	display: flex;
	flex-direction: column;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.adminReact-table_ReactTable__xNjQl .adminReact-table_rt-thead__1eE25.adminReact-table_-headerGroups__50Jl9 {
	background: rgba(0, 0, 0, 0.03);
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.adminReact-table_ReactTable__xNjQl .adminReact-table_rt-thead__1eE25.adminReact-table_-filters__3XzEX {
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.adminReact-table_ReactTable__xNjQl .adminReact-table_rt-thead__1eE25.adminReact-table_-filters__3XzEX input,
.adminReact-table_ReactTable__xNjQl .adminReact-table_rt-thead__1eE25.adminReact-table_-filters__3XzEX select {
	border: 1px solid rgba(0, 0, 0, 0.1);
	background: var(--reacttable-input);
	padding: 5px 7px;
	font-size: inherit;
	border-radius: 3px;
	font-weight: normal;
	outline: none;
	color: var(--normalfont-color);
}

.adminReact-table_ReactTable__xNjQl .adminReact-table_rt-thead__1eE25.adminReact-table_-filters__3XzEX .adminReact-table_rt-th__1cwlS {
	border-right: 1px solid rgba(0, 0, 0, 0.02);
}

.adminReact-table_ReactTable__xNjQl .adminReact-table_rt-thead__1eE25.adminReact-table_-header__AF9oM {
	box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);
}

.adminReact-table_ReactTable__xNjQl .adminReact-table_rt-thead__1eE25 .adminReact-table_rt-tr__2Y8mK {
	text-align: left;
	color: var(--mainfont-color);
	font-weight: 600;
	background-color: var(--primary-accent);
	padding: 1rem;
	border-radius: 0.3rem 0.3rem 0 0;
}
.adminReact-table_ReactTable__xNjQl .adminReact-table_rt-thead__1eE25.adminReact-table_-filters__3XzEX .adminReact-table_rt-tr__2Y8mK {
	text-align: left;
	color: var(--mainfont-color);
	font-weight: 600;
	background-color: var(--default-bg);
	/* background-color: white; */
	padding: 1rem;
	border-radius: 0rem 0rem 0 0;
}
.adminReact-table_ReactTable__xNjQl .adminReact-table_rt-thead__1eE25 .adminReact-table_rt-th__1cwlS,
.adminReact-table_ReactTable__xNjQl .adminReact-table_rt-thead__1eE25 .adminReact-table_rt-td__3J2Eg {
	padding: 5px 5px;
	line-height: normal;
	position: relative;
	border-right: 1px solid rgba(0, 0, 0, 0.05);
	transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
	box-shadow: inset 0 0 0 0 transparent;
}

.adminReact-table_ReactTable__xNjQl .adminReact-table_rt-thead__1eE25 .adminReact-table_rt-th__1cwlS.adminReact-table_-sort-asc__3Qfo_,
.adminReact-table_ReactTable__xNjQl .adminReact-table_rt-thead__1eE25 .adminReact-table_rt-td__3J2Eg.adminReact-table_-sort-asc__3Qfo_ {
	box-shadow: inset 0 3px 0 0 #ffffff !important;
}

.adminReact-table_ReactTable__xNjQl .adminReact-table_rt-thead__1eE25 .adminReact-table_rt-th__1cwlS.adminReact-table_-sort-desc__2TT6K,
.adminReact-table_ReactTable__xNjQl .adminReact-table_rt-thead__1eE25 .adminReact-table_rt-td__3J2Eg.adminReact-table_-sort-desc__2TT6K {
	box-shadow: inset 0 -3px 0 0 #ffffff !important;
}

.adminReact-table_ReactTable__xNjQl .adminReact-table_rt-thead__1eE25 .adminReact-table_rt-th__1cwlS.adminReact-table_-cursor-pointer__2DH12,
.adminReact-table_ReactTable__xNjQl .adminReact-table_rt-thead__1eE25 .adminReact-table_rt-td__3J2Eg.adminReact-table_-cursor-pointer__2DH12 {
	cursor: pointer;
}

.adminReact-table_ReactTable__xNjQl .adminReact-table_rt-thead__1eE25 .adminReact-table_rt-th__1cwlS:last-child,
.adminReact-table_ReactTable__xNjQl .adminReact-table_rt-thead__1eE25 .adminReact-table_rt-td__3J2Eg:last-child {
	border-right: 0;
}

.adminReact-table_ReactTable__xNjQl .adminReact-table_rt-thead__1eE25 .adminReact-table_rt-resizable-header__3Yz-d {
	overflow: visible;
}

.adminReact-table_ReactTable__xNjQl .adminReact-table_rt-thead__1eE25 .adminReact-table_rt-resizable-header__3Yz-d:last-child {
	overflow: hidden;
}

.adminReact-table_ReactTable__xNjQl .adminReact-table_rt-thead__1eE25 .adminReact-table_rt-resizable-header-content__2tqFi {
	overflow: hidden;
	text-overflow: ellipsis;
}

.adminReact-table_ReactTable__xNjQl .adminReact-table_rt-thead__1eE25 .adminReact-table_rt-header-pivot__iRTxK {
	border-right-color: #f7f7f7;
}

.adminReact-table_ReactTable__xNjQl .adminReact-table_rt-thead__1eE25 .adminReact-table_rt-header-pivot__iRTxK:after,
.adminReact-table_ReactTable__xNjQl .adminReact-table_rt-thead__1eE25 .adminReact-table_rt-header-pivot__iRTxK:before {
	left: 100%;
	top: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

.adminReact-table_ReactTable__xNjQl .adminReact-table_rt-thead__1eE25 .adminReact-table_rt-header-pivot__iRTxK:after {
	border-color: rgb(255, 255, 255, 0);
	border-left-color: #fff;
	border-width: 8px;
	margin-top: -8px;
}

.adminReact-table_ReactTable__xNjQl .adminReact-table_rt-thead__1eE25 .adminReact-table_rt-header-pivot__iRTxK:before {
	border-color: rgb(255, 255, 255);
	border-left-color: #f7f7f7;
	border-width: 10px;
	margin-top: -10px;
}

.adminReact-table_ReactTable__xNjQl .adminReact-table_rt-tbody__3iNJL {
	flex: 99999 1 auto;
	display: flex;
	flex-direction: column;
	overflow: auto;
	/* background-color: white; */
}

.adminReact-table_ReactTable__xNjQl .adminReact-table_rt-tbody__3iNJL .adminReact-table_rt-tr-group__ZouPA {
	border-bottom: solid 1px rgba(0, 0, 0, 0.05);
}

.adminReact-table_ReactTable__xNjQl .adminReact-table_rt-tbody__3iNJL .adminReact-table_rt-tr-group__ZouPA:last-child {
	border-bottom: 0;
}

.adminReact-table_ReactTable__xNjQl .adminReact-table_rt-tbody__3iNJL .adminReact-table_rt-td__3J2Eg {
	border-right: 1px solid rgba(0, 0, 0, 0.02);
	padding-left: 1.5rem;
	color: var(--normalfont-color);
}

.adminReact-table_ReactTable__xNjQl .adminReact-table_rt-tbody__3iNJL .adminReact-table_rt-td__3J2Eg:last-child {
	border-right: 0;
}

.adminReact-table_ReactTable__xNjQl .adminReact-table_rt-tbody__3iNJL .adminReact-table_rt-expandable__36Jos {
	cursor: pointer;
	text-overflow: clip;
}

.adminReact-table_ReactTable__xNjQl .adminReact-table_rt-tr-group__ZouPA {
	flex: 1 0 auto;
	display: flex;
	flex-direction: column;
	align-items: stretch;
}

.adminReact-table_ReactTable__xNjQl .adminReact-table_rt-tr__2Y8mK {
	flex: 1 0 auto;
	display: inline-flex;
}

.adminReact-table_ReactTable__xNjQl .adminReact-table_rt-th__1cwlS,
.adminReact-table_ReactTable__xNjQl .adminReact-table_rt-td__3J2Eg {
	flex: 1 0;
	white-space: nowrap;
	text-overflow: ellipsis;
	padding: 7px 5px;
	overflow: hidden;
	transition: 0.3s ease;
	transition-property: width, min-width, padding, opacity;
}

.adminReact-table_ReactTable__xNjQl .adminReact-table_rt-th__1cwlS.adminReact-table_-hidden__1-05z,
.adminReact-table_ReactTable__xNjQl .adminReact-table_rt-td__3J2Eg.adminReact-table_-hidden__1-05z {
	width: 0 !important;
	min-width: 0 !important;
	padding: 0 !important;
	border: 0 !important;
	opacity: 0 !important;
}

.adminReact-table_ReactTable__xNjQl .adminReact-table_rt-expander__uWNpe {
	display: inline-block;
	position: relative;
	margin: 0;
	color: transparent;
	margin: 0 10px;
}

.adminReact-table_ReactTable__xNjQl .adminReact-table_rt-expander__uWNpe:after {
	content: "";
	position: absolute;
	width: 0;
	height: 0;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) rotate(-90deg);
	border-left: 5.04px solid transparent;
	border-right: 5.04px solid transparent;
	border-top: 7px solid rgba(0, 0, 0, 0.8);
	transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
	cursor: pointer;
}

.adminReact-table_ReactTable__xNjQl .adminReact-table_rt-expander__uWNpe.adminReact-table_-open__3D6gs:after {
	transform: translate(-50%, -50%) rotate(0);
}

.adminReact-table_ReactTable__xNjQl .adminReact-table_rt-resizer__3-nHM {
	display: inline-block;
	position: absolute;
	width: 36px;
	top: 0;
	bottom: 0;
	/* right: -18px; */
	cursor: col-resize;
	z-index: 10;
	color: white;
}

.adminReact-table_ReactTable__xNjQl .adminReact-table_rt-tfoot__1azCk {
	flex: 1 0 auto;
	display: flex;
	flex-direction: column;
	box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
}

.adminReact-table_ReactTable__xNjQl .adminReact-table_rt-tfoot__1azCk .adminReact-table_rt-td__3J2Eg {
	border-right: 1px solid rgba(0, 0, 0, 0.05);
}

.adminReact-table_ReactTable__xNjQl .adminReact-table_rt-tfoot__1azCk .adminReact-table_rt-td__3J2Eg:last-child {
	border-right: 0;
}

.adminReact-table_ReactTable__xNjQl.adminReact-table_-striped__2AXuG .adminReact-table_rt-tr__2Y8mK.adminReact-table_-odd__3ylXP {
	background: var(--reacttable-odd)
}
.adminReact-table_ReactTable__xNjQl.adminReact-table_-striped__2AXuG .adminReact-table_rt-tr__2Y8mK.adminReact-table_-even__2R86G {
	background: var(--default-bg)
}
.adminReact-table_ReactTable__xNjQl.adminReact-table_-highlight__3lMPg .adminReact-table_rt-tbody__3iNJL .adminReact-table_rt-tr__2Y8mK:not(.adminReact-table_-padRow__3lFIa):hover {
	background: var(--reacttable-hover);
	cursor: pointer;
}

.adminReact-table_ReactTable__xNjQl .adminReact-table_-pagination__1zlzk {
	z-index: 1;
	display: flex;
	justify-content: space-between;
	align-items: stretch;
	flex-wrap: wrap;
	padding: 3px;
	box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
	border-top: 2px solid rgba(0, 0, 0, 0.1);
}

.adminReact-table_ReactTable__xNjQl .adminReact-table_-pagination__1zlzk input,
.adminReact-table_ReactTable__xNjQl .adminReact-table_-pagination__1zlzk select {
	border: 1px solid rgba(0, 0, 0, 0.1);
	background: var(--default-bg)!important;
	padding: 5px 7px;
	font-size: inherit;
	border-radius: 3px;
	font-weight: normal;
	outline: none;
	color: var(--normalfont-color);
}
.adminReact-table_ReactTable__xNjQl .adminReact-table_-pagination__1zlzk option {
	background-color: var(--default-bg);
	color: var(--normalfont-color);
}
.adminReact-table_ReactTable__xNjQl .adminReact-table_-pagination__1zlzk .adminReact-table_-btn__1A7XU {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	display: block;
	width: 80%;
	height: 100%;
	border: 0;
	border-radius: 3px;
	padding: 6px;
	font-size: 1em;
	color: var(--normalfont-color);
	background: transparent;
	transition: all 0.1s ease;
	cursor: pointer;
	outline: none;
}
.adminReact-table_ReactTable__xNjQl .adminReact-table_-pagination__1zlzk .adminReact-table_-btn__1A7XU[disabled] {
	opacity: .1;
	cursor: default;
	/* color: rgba(0, 0, 0, 0.6); */
}

.adminReact-table_ReactTable__xNjQl .adminReact-table_-pagination__1zlzk .adminReact-table_-btn__1A7XU:not([disabled]):hover {
	background: var(--reacttable-hover);
	/* color: var(--normalfont-color); */
	color: var(--normalfont-color);
}

.adminReact-table_ReactTable__xNjQl .adminReact-table_-pagination__1zlzk .adminReact-table_-previous__12aw0 {
	flex: 1 1;
	/* text-align:left; */
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
}
.adminReact-table_ReactTable__xNjQl .adminReact-table_-pagination__1zlzk .adminReact-table_-next__1nmNq {
	flex: 1 1;
	/* text-align:right; */
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}
.adminReact-table_ReactTable__xNjQl .adminReact-table_-pagination__1zlzk .adminReact-table_-center__3C-0m {
	flex: 1.5 1;
	text-align: center;
	margin-bottom: 0;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-around;
}

.adminReact-table_ReactTable__xNjQl .adminReact-table_-pagination__1zlzk .adminReact-table_-pageInfo__3Ozr0 {
	display: inline-block;
	margin: 3px 10px;
	white-space: nowrap;
	color: var(--normalfont-color);
}

.adminReact-table_ReactTable__xNjQl .adminReact-table_-pagination__1zlzk .adminReact-table_-pageJump__3Ox3F {
	display: inline-block;
}

.adminReact-table_ReactTable__xNjQl .adminReact-table_-pagination__1zlzk .adminReact-table_-pageJump__3Ox3F input {
	width: 70px;
	text-align: center;
}

.adminReact-table_ReactTable__xNjQl .adminReact-table_-pagination__1zlzk .adminReact-table_-pageSizeOptions__2qn8V {
	margin: 3px 10px;
}

.adminReact-table_ReactTable__xNjQl .adminReact-table_rt-noData__301p9 {
	display: block;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	background: rgba(255, 255, 255, 0.8);
	transition: all 0.3s ease;
	z-index: 1;
	pointer-events: none;
	padding: 20px;
	color: rgba(0, 0, 0, 0.5);
}

.adminReact-table_ReactTable__xNjQl .adminReact-table_-loading__3G-jg {
	display: block;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: var(--default-bg);
	transition: all 0.3s ease;
	z-index: -1;
	opacity: 0;
	pointer-events: none;
}

.adminReact-table_ReactTable__xNjQl .adminReact-table_-loading__3G-jg > div {
	position: absolute;
	display: block;
	text-align: center;
	width: 100%;
	top: 50%;
	left: 0;
	font-size: 15px;
	color: var(--normalfont-color);
	transform: translateY(-52%);
	transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.adminReact-table_ReactTable__xNjQl .adminReact-table_-loading__3G-jg.adminReact-table_-active__1Ir2M {
	opacity: 0.9;
	z-index: 2;
	pointer-events: all;
}

.adminReact-table_ReactTable__xNjQl .adminReact-table_-loading__3G-jg.adminReact-table_-active__1Ir2M > div {
	transform: translateY(50%);
}

.adminReact-table_ReactTable__xNjQl .adminReact-table_rt-resizing__2jWYE .adminReact-table_rt-th__1cwlS,
.adminReact-table_ReactTable__xNjQl .adminReact-table_rt-resizing__2jWYE .adminReact-table_rt-td__3J2Eg {
	transition: none !important;
	cursor: col-resize;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

:root{
    --primary-normal:#9b41d6;
    --font-family: 'Poppins', sans-serif;
    --edit-normal:#00c400;
    --edit-hover:#00df00;
    --edit-active:#00a000;
    --delete-normal:red;
    --delete-hover:#ff3e3e;
    --delete-active:#bd0000;

    --default-bg:#ffffff;
    --body-bg:#e6e6e6;
    --settings-bg:#f3f3f3;
    --reacttable-input:#ffffff;
    --reactcalendar-button-hover:#e6e6e6;


    --primary-accent:#9500ff;
    --primary-active:#8100dd;
    --primary-hover:#a322ff;
    --reacttable-odd:#e0b5ff59;
    --reacttable-hover:#bf64ff59;
    --reactcalendar-now:#fad2ff;
    --reactcalendar-now-hover:#f4bfff;
    --appearance-subcard-bg:#e3e3e3;
    --mainfont-color:#ffffff;

    --normalfont-color:#000000;

    --tableborder-color:#dbdbdb
}
.settingsSubMenu_wrapperSettingMenu__1vOnr{
    position: relative;
    background-color: var(--default-bg);
    top: 2rem;
    width: 16rem;
    margin-left: 2rem;
    border-radius: 0.3rem;
    height: calc(100vh - 8.5rem);
}
.settingsSubMenu_SettingMenu__1YCKX{
    height: 100%;
    padding: 1rem 0 1rem 0;
}
.settingsSubMenu_SettingMenu__1YCKX li{
    text-align: left;
    padding: 0.5rem 0 0.5rem 1rem;
    /* border: solid 1px #f3f3f3; */
}
.settingsSubMenu_SettingMenu__1YCKX li:hover{
    cursor: pointer;
}
.settingsSubMenu_SettingMenu__1YCKX li a{
    color: var(--normalfont-color);
    font-size: 1rem;
    text-decoration: none;
}
.settingsSubMenu_SettingMenu__1YCKX li:hover{
    background-color: var(--settings-bg);
}
.settingsSubMenu_SettingMenu__1YCKX ul{
    margin-bottom: 0;
}
.settingsSubMenu_highlighted__XXuIr{
    border-left: 5px solid var(--primary-accent)!important;
    background-color: var(--settings-bg);
    padding-left: 11px!important;
}
.settingsSubMenu_main_content__1PzX8{
    width: auto;
	margin-left: 4rem;
	padding-top: 4.5rem;
    display: flex;
    flex-direction: row;
    overflow-x: auto;
}
.settingsSubMenu_settingsOptionComponent__-hzr-{
    position: relative;
    top: 2rem;
    background-color: var(--default-bg);
    border-radius: 0.3rem;
    margin-left: 2rem;
    height: calc(100vh - 8.5rem);
    width: calc(100vw - 21rem);
    margin-right: 2rem;
}

.settingsChangePasswordComponent_wrapper__2iWoi{
    width: 100%;
    height: 100%;
    background-color: var(--default-bg);
    border-radius: 0.3rem;
    padding: 1rem;
    overflow-y: auto;
}
.settingsChangePasswordComponent_header__3ibfK{
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--normalfont-color);
    text-align: left;
}
.settingsChangePasswordComponent_formBody__385pF{
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 90%;
}
.settingsChangePasswordComponent_formGroup__3NGvT{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 1rem;
    width: 100%;
}
.settingsChangePasswordComponent_formGroup__3NGvT .settingsChangePasswordComponent_input__2MaWE{
    border-radius: 0.3rem;
    border: solid 2px #575757!important;
    padding: 0.3rem;
    background-color: var(--default-bg);
    color: var(--normalfont-color);
}
.settingsChangePasswordComponent_formGroup__3NGvT .settingsChangePasswordComponent_input__2MaWE:focus, .settingsChangePasswordComponent_formGroup__3NGvT .settingsChangePasswordComponent_input__2MaWE:active{
    border: solid 2px var(--primary-accent)!important;
}
.settingsChangePasswordComponent_innerCont__2Bdhw{
    width: 100%;
    display: flex;
    flex-direction: row;
}
.settingsChangePasswordComponent_innerCont__2Bdhw span{
    padding: 0.3rem;
    color: red;
    font-size: 0.9rem;
}
label{
    color: var(--normalfont-color);
}
.settingsChangeAppearanceComponent_wrapper__RnC54{
    width: 100%;
    height: 100%;
    background-color: var(--default-bg);
    border-radius: 0.3rem;
    padding: 1rem;
    overflow-y: auto;
}
.settingsChangeAppearanceComponent_header__1FUKS{
    font-size: 1.5rem;
    font-weight: bold;
    text-align: left;
    color: var(--normalfont-color);
}
.settingsChangeAppearanceComponent_colorArea__o6NMS{
    width: 95%;
    background-color: var(--settings-bg);
    margin: 1rem;
    border-radius: 0.3rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 1rem;
}
.settingsChangeAppearanceComponent_subHeader__3EzvD{
    font-size: 1rem;
    font-weight: 600;
    color: var(--normalfont-color);
    text-align: left;
}
.settingsChangeAppearanceComponent_colorButton__39QOs{
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 0;
    cursor: pointer;
    margin-right: 1rem;
}
.settingsChangeAppearanceComponent_colorButton_selected__c2Vad{
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;
    cursor: pointer;
    /* border: white solid 4px; */
}
.settingsChangeAppearanceComponent_lightCheck__1tKUs{
    color: white;
    font-size: 1.5rem;
}
.settingsChangeAppearanceComponent_darkCheck__2Fip9{
    color: #3a3a3a;
    font-size: 1.5rem;
}
.settingsChangeAppearanceComponent_redTheme___cu91{
    background-color: #ff0000;
}
.settingsChangeAppearanceComponent_defaultTheme__1Qlim{
    background-color: #9500ff;
}
.settingsChangeAppearanceComponent_yellowTheme__2IhpL{
    background-color: #ffd600;
}
.settingsChangeAppearanceComponent_blueTheme__19aFk{
    background-color: #0047ff;
}
.settingsChangeAppearanceComponent_orangeTheme__2x5Bm{
    background-color: #ff7a00;
}
.settingsChangeAppearanceComponent_greenTheme__2vg7D{
    background-color: #20E700;
}
.settingsChangeAppearanceComponent_defaultBg__3TMAU{
    background-color: white;
}
.settingsChangeAppearanceComponent_darkBg__20LBn{
    background-color: #3a3a3a;
}
