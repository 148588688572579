:root{
    --primary-normal:#9b41d6;
    --font-family: 'Poppins', sans-serif;
    --edit-normal:#00c400;
    --edit-hover:#00df00;
    --edit-active:#00a000;
    --delete-normal:red;
    --delete-hover:#ff3e3e;
    --delete-active:#bd0000;

    --default-bg:#ffffff;
    --body-bg:#e6e6e6;
    --settings-bg:#f3f3f3;
    --reacttable-input:#ffffff;
    --reactcalendar-button-hover:#e6e6e6;


    --primary-accent:#9500ff;
    --primary-active:#8100dd;
    --primary-hover:#a322ff;
    --reacttable-odd:#e0b5ff59;
    --reacttable-hover:#bf64ff59;
    --reactcalendar-now:#fad2ff;
    --reactcalendar-now-hover:#f4bfff;
    --appearance-subcard-bg:#e3e3e3;
    --mainfont-color:#ffffff;

    --normalfont-color:#000000;

    --tableborder-color:#dbdbdb
}