@import "allColors.css";
/* .*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  font-family: 'Josefin Sans', sans-serif;
} */

/* Sidebar Navigation Bar */
html{
  font-size: 16px;
}
.wrapperNavbar .sidebar{
  width: 4rem;
  height: 100%;
  background:var(--default-bg);
  padding: 30px 0px;
  position: fixed;
  z-index: 10;
  left: 0%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.wrapperNavbar .sidebar h2{
  color: var(--normalfont-color);
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 30px;
}

.wrapperNavbar .sidebar ul{
  /* padding: 15px; */
  /* border-bottom: 1px solid #bdb8d7; */
  /* border-bottom: 1px solid rgba(0,0,0,0.05); */
  /* border-top: 1px solid rgba(255,255,255,0.05); */
  margin-top: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 80vh;
  justify-content: center;
}
.wrapperNavbar .sidebar ul li{
  /* padding: 15px; */
  /* border-bottom: 1px solid #bdb8d7; */
  /* border-bottom: 1px solid rgba(0,0,0,0.05); */
  /* border-top: 1px solid rgba(255,255,255,0.05); */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.wrapperNavbar .sidebar ul li a{
  margin-top: 2vh;
  color: var(--normalfont-color);
  display: block;
  text-align: left;
  text-decoration: none;
  border: transparent solid 3px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 5px;
  width: 3rem;
  height: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.wrapperNavbar .sidebar ul li a .fas{
  width: 25px;
  }

.wrapperNavbar .sidebar ul li:hover{
  background-color:var(--default-bg);
}

.wrapperNavbar .sidebar ul li:hover a{
  color: var(--mainfont-color);
  text-decoration: none;
  font-weight: bold;
  border: solid 3px var(--primary-accent);
}
.wrapperNavbar .sidebar ul li:hover a svg *{
  fill: var(--primary-accent);
}
.highlighted a{
  border: solid 3px var(--primary-accent)!important;
}
.highlighted a svg *{
  fill: var(--primary-accent);
}
.innerList1{
  display: none;
  position: absolute;
  left: 100%;
  /* top: ; */
  width: 100%;
  top: 16.45%;
  background-color:var(--primary-hover);
  opacity: 1;
}
.innerList2{
  display: none;
  position: absolute;
  left: 100%;
  /* top: ; */
  width: 100%;
  top: 40%;
  background-color:var(--primary-hover);
  opacity: 1;
}
.wrapperNavbar .sidebar ul li:nth-child(2):hover .innerList1{
  display: block;
}
.wrapperNavbar .sidebar ul li:nth-child(6):hover .innerList2{
  display: block;
}
/* .wrapperNavbar .sidebar ul li:hover > ul{
  opacity: 1;
  visibility: visible;
  left:0%;
} */
/* .cont1{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  background-color: red;
} */
#zaltFooter{
  position: fixed;
  bottom: 2vh
}